import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { BsDownload, BsPrinter } from "react-icons/bs";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for loading spinner

const RequestTable = () => {
  const labelStyle = { fontWeight: "normal" };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true); // Start loading as true
  const [accessLevel, setAccessLevel] = useState();
  useEffect(() => {
    const al = Cookies.get("accessLevel");
    setAccessLevel(al);
  }, []);
  const [renderTrigger, setRenderTrigger] = useState(0);

  const forceRerender = () => {
    setRenderTrigger((prev) => prev + 1); // Increment to force a re-renderq
  };

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.nha.gov.ph/ep/fetch_requests",
        {
          withCredentials: true,
        }
      );
      const formattedRequests =
        Array.isArray(response.data.requests) &&
        response.data.requests.length > 0
          ? response.data.requests.map((request, index) => ({
              requestNumber: request.requestNumber || "",
              areaClassification: request.areaClassification || "",
              projectName: request.projectName || "",
              requestCount: request.beneficiaryId.length || null,
              key: index,
              selectedIds: request.beneficiaryId,
              status: request.status,
            }))
          : [];

      setRequests(formattedRequests);
    } catch (error) {
      console.error("Error fetching batches:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false after the fetch
      forceRerender();
    }
  };

  const handleDownload = (requestNumber) => {
    const fileUrl = `https://api.nha.gov.ph/ep/pdf_download/${requestNumber}`;

    fetch(fileUrl, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("File not found or server error");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${requestNumber}.pdf`; // Set the file name if needed
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error("Download error:", error);
        alert("Error downloading file. Please try again later.");
      });
  };

  useEffect(() => {
    fetchRequests();
  }, [pathname]);

  const handleNextEp = (index) => {
    const request = requests[index];
    if (request) {
      const { requestNumber, selectedIds } = request;
      const state = { requestNumber, selectedIds };
      navigate(`/ep`, { state });
    } else {
      toast.error("Please select beneficiaries");
    }
  };

  const date = new Date();
  const formattedDateTime = `${date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })} ${date.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Singapore",
  })}`;

  const handleRequest = async (request) => {
    console.log("triggered button");
    if (request) {
      let columnToUpdate = "";
      let state = {
        requestNumber: request.requestNumber.toString(),
        selectedIds: request.beneficiaryId,
      };

      // Set columnToUpdate for RDSD levels
      if (accessLevel === "0") {
        columnToUpdate = "rdsdIn";
        state.rdsdIn = formattedDateTime;
      } else if (accessLevel === "0.1") {
        columnToUpdate = "rdsdRAIn";
      } else if (accessLevel === "0.2") {
        columnToUpdate = "rdsdDMIn";
      } else if (accessLevel === "1") {
        state.regionalIn = formattedDateTime;
      }

      try {
        // Only make API call if columnToUpdate exists (RDSD levels)
        if (columnToUpdate) {
          const payload = {
            requestNumber: request.requestNumber.toString(),
            [columnToUpdate]: formattedDateTime,
          };

          console.log("Sending payload:", payload);

          const response = await axios.put(
            `https://api.nha.gov.ph/ep/trx_update_entry_pass_requisition`,
            payload,
            {
              withCredentials: true,
              headers: { "Content-Type": "application/json" },
            }
          );

          if (response.status === 200) {
            toast.success("Entry Pass Requisition updated successfully");
          }
        }

        // Navigate regardless of API call
        navigate(`/request/${request.requestNumber}`, { state });
      } catch (error) {
        console.error("Error details:", error);
        if (error.response?.status === 404) {
          toast.error("Entry Pass Requisition not found");
        } else {
          toast.error(
            error.response?.data?.error ||
              "Error updating Entry Pass Requisition"
          );
        }
      }
    } else {
      toast.error("ERROR");
    }
  };

  const filteredRequests = requests.filter((request) =>
    request.requestNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>
                  Request{" "}
                  {accessLevel == 2
                    ? "(District Office)"
                    : accessLevel < 2 && accessLevel >= 1
                    ? "(Regional Office)"
                    : accessLevel < 1 && accessLevel <= 0
                    ? "(RDSD)"
                    : ""}
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-2 pr-2">
          <div className="container-fluid">
            <div className="mt-3">
              <div style={{ display: "flex" }}>
                <p className="pt-2 mr-2">Search by Request Number:</p>{" "}
                <TextField
                  placeholder="Search here"
                  className="mb-3"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div
                className="card elevation-2"
                style={{
                  borderTop: "4px solid #292726",
                  borderRadius: "15px",
                }}
              >
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">
                    List of Request
                  </h3>
                </div>
                <div
                  className="card-body table-responsive p-0"
                  style={{ height: "65vh" }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : requests.length === 0 ? (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      <p>No requests found.</p>
                    </div>
                  ) : (
                    <table className="table table-head-fixed table-hover table-bordered text-wrap">
                      <thead>
                        <tr>
                          {/* <th>
                                                        <input
                                                            type="checkbox"
                                                            style={{
                                                                width: "12px",
                                                            }}
                                                        />
                                                    </th> */}
                          <th
                            className="text-center"
                            style={{
                              width: "150px",
                            }}
                          >
                            Sequence No.
                          </th>
                          <th className="text-center">Request Number</th>
                          <th className="text-center">
                            Program Classification
                          </th>
                          <th className="text-center">Project Name</th>
                          <th className="text-center">Number of Families</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Print</th>
                          <th className="text-center">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredRequests.map((request, index) => (
                          <tr key={index} className="td-hover">
                            {/* <td className="text-center">
                                                                {request.projectName ? (
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{
                                                                            width: "12px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            height: "19px",
                                                                        }}
                                                                    ></div>
                                                                )}
                                                            </td> */}
                            {request.projectName ? (
                              <td className="text-center">{index + 1}</td>
                            ) : (
                              <td> </td>
                            )}
                            <td className="text-center">
                              {request.requestNumber}
                            </td>
                            <td className="text-center">
                              {request.areaClassification}
                            </td>
                            <td className="text-center">
                              {request.projectName}
                            </td>
                            <td className="text-center">
                              {request.requestCount}
                            </td>
                            <td className="text-center">{request.status}</td>
                            {request.projectName &&
                            request.status.trim() !== "With Printed EP" &&
                            request.status.trim() !== "Approved by RDSD" ? (
                              <td className="text-center">
                                <a
                                  onClick={() =>
                                    handleDownload(request.requestNumber)
                                  }
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: "#2E5984",
                                  }}
                                >
                                  <BsDownload />
                                </a>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {(request.projectName &&
                              accessLevel == 1 &&
                              request.status.trim() === "Approved by RDSD") ||
                            (accessLevel == 1 &&
                              request.status.trim() === "With Printed EP") ? (
                              <td className="text-center">
                                <a
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: "#2E5984",
                                  }}
                                  onClick={() => handleNextEp(index)}
                                >
                                  <BsPrinter />
                                </a>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {request.projectName &&
                            ((accessLevel == 0 &&
                              (request.status ===
                                "Approved by Regional Office" ||
                                request.status ===
                                  "Approved by Regional Manager")) ||
                              (accessLevel != 0 &&
                                request.status != "Approved by RDSD" &&
                                request.status != "With Printed EP")) ? (
                              <td className="text-center">
                                <a
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    color: "#2E5984",
                                  }}
                                  onClick={() => handleRequest(request)}
                                >
                                  Request
                                </a>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div
                  className="card-footer"
                  style={{
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <div className="row cardFooterCenter">
                    {/* <div className="col-sm-6">
                                            <label>1</label>{" "}
                                            <span className="text-muted">
                                                of
                                            </span>{" "}
                                            <label>3</label> &nbsp;
                                            <IconButton>
                                                <ArrowBackIosIcon className="text-md pl-1" />
                                            </IconButton>
                                            <IconButton>
                                                <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                            </IconButton>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RequestTable;
