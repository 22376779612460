import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import IconButton from "@mui/material/IconButton";
// mui icon
import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Button,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BsPrinter } from "react-icons/bs";
import Modal from "./SignatoryModal"; // Import the Modal component
import Cookies from "js-cookie";
import { borderRadius, width } from "@mui/system";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const Batch = () => {
  axios.defaults.withCredentials = true;
  const [accessLevel, setAccessLevel] = useState();
  useEffect(() => {
    const al = Cookies.get("accessLevel");
    setAccessLevel(al);
  }, []);
  const [filterField, setFilterField] = useState("batchNumber"); // Field to filter by
  const [filterValue, setFilterValue] = useState(""); // Value to filter
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [batches, setBatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false); // State for modal visibility
  const [formData, setFormData] = useState({
    preparedBy: "",
    reviewedBy: "",
    certifiedCorrect: "",
    approvedBy: "",
    designation: "",
    designation2: "",
    designation3: "",
    designation4: "",
    modalBatchNumber: "",
    // Add more textboxes as needed
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await axios.get(
          "https://api.nha.gov.ph/ep/api/batches",
          { withCredentials: true }
        );
        const formattedBatches = response.data.map((batch) => {
          const beneficiaries = batch.Beneficiaries || [];
          const modalNumber = response.data[0].batchNumber;
          const firstBeneficiary = beneficiaries[0] || {};
          const resettlementSiteName =
            firstBeneficiary.Resettlement?.resettlementSiteName || "";
          const projectName = firstBeneficiary.PointOfOrigin?.projectName || "";
          setFormData({
            ...formData,
            modalBatchNumber: modalNumber,
          });
          return {
            batchNumber: batch.batchNumber,
            resettlementSiteName,
            projectName,
            beneficiaryCount: beneficiaries.length,
          };
        });

        setBatches(formattedBatches);
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchBatches();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBatches = batches.slice(indexOfFirstItem, indexOfLastItem);
  const remainingBlanks = itemsPerPage - currentBatches.length;
  const blankBatch = {
    index: "",
    batchNumber: "",
    resettlementSiteName: "",
    projectName: "",
    beneficiaryCount: null,
  };

  for (let i = 0; i < remainingBlanks; i++) {
    currentBatches.push(blankBatch);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openPrintModal = (modalBatchNumber) => {
    setIsPrintModalOpen(true);
    setFormData({ ...formData, modalBatchNumber });
  };

  const closePrintModal = () => {
    setIsPrintModalOpen(false);
  };

  useEffect(() => {
    if (batches) {
      const filtered = batches.filter((batch) =>
        (batch[filterField] || "")
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
      setFilteredBatches(filtered);
    }
  }, [filterField, filterValue, batches]);

  const downloadExcel = () => {
    if (!filteredBatches || filteredBatches.length === 0) {
      alert("No data to download!");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Batch Report");

    // Title styles
    const titleStyleLeft = {
      font: { size: 12 },
      alignment: { horizontal: "left", vertical: "middle" },
    };

    const titleStyleCenter = {
      font: { size: 16, bold: true },
      alignment: { horizontal: "left", vertical: "middle" },
    };

    // Add title rows
    worksheet.getCell("A1").value = "National Housing Authority";
    worksheet.getCell("A1").style = titleStyleLeft;

    worksheet.getCell("A2").value =
      "Resettlement & Development Services Department";
    worksheet.getCell("A2").style = titleStyleLeft;

    worksheet.getCell("A3").value =
      "Relocation & Resettlement Monitoring Division";
    worksheet.getCell("A3").style = titleStyleLeft;

    worksheet.getCell("A4").value = "BATCH REPORT";
    worksheet.getCell("A4").style = titleStyleCenter;

    // Adjust row heights for title
    worksheet.getRow(1).height = 20;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 25;

    // Define header styles
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F4F4F4" },
      },
    };

    // Define header rows
    worksheet.mergeCells("A6:A7"); // NO.
    worksheet.mergeCells("B6:D6"); // BATCH DETAILS

    worksheet.getRow(6).height = 30;
    worksheet.getRow(7).height = 20;

    // Add header titles
    worksheet.getCell("A6").value = "NO.";
    worksheet.getCell("B6").value = "BATCH DETAILS";

    worksheet.getCell("B7").value = "BATCH NUMBER";
    worksheet.getCell("C7").value = "PROGRAM CLASSIFICATION";
    worksheet.getCell("D7").value = "PROJECT NAME";

    // Apply styles to headers
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber >= 6 && rowNumber <= 7) {
        row.eachCell((cell) => {
          cell.style = headerStyle;
        });
      }
    });

    // Add data rows
    filteredBatches.forEach((batch, index) => {
      const row = worksheet.addRow([
        index + 1,
        batch.batchNumber || "-",
        batch.resettlementSiteName || "-",
        batch.projectName || "-",
      ]);

      // Apply borders to data rows
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    // Auto-size columns
    worksheet.columns.forEach((column) => {
      column.width = Math.max(
        ...column.values.map((val) => (val ? val.toString().length : 10))
      );
    });

    // Save workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), "FilteredBatchReport.xlsx");
    });
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Batch</h1>
                <Grid container spacing={2}>
                  {/* Filter controls */}
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="filterField">Filter By</InputLabel>
                      <Select
                        id="filterField"
                        value={filterField}
                        onChange={(e) => setFilterField(e.target.value)}
                        label="Filter By"
                      >
                        <MenuItem value="batchNumber">Batch Number</MenuItem>
                        <MenuItem value="resettlementSiteName">
                          Resettlement Site
                        </MenuItem>
                        <MenuItem value="projectName">Project Name</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      label={`Filter by ${filterField}`}
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadExcel}
                  sx={{ mt: 2 }}
                >
                  Download Excel
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-2 pr-2">
          <div className="container-fluid">
            <div className="mt-4">
              <div
                className="card elevation-2"
                style={{
                  borderTop: "4px solid #292726",
                  borderRadius: "15px",
                }}
              >
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">List of Batch</h3>
                </div>

                <div
                  className="card-body table-responsive p-0"
                  style={{ height: "65vh" }}
                >
                  <table className="table table-head-fixed table-hover table-bordered text-wrap">
                    <thead>
                      <tr>
                        <th className="text-center">Sequence No.</th>
                        <th className="text-center">Batch Number</th>
                        <th className="text-center">Resettlement Site</th>
                        <th className="text-center">Project Name</th>
                        <th className="text-center">Count</th>
                        <th className="text-center">Action</th>
                        {accessLevel == 2 ? (
                          <th className="text-center">Request</th>
                        ) : (
                          <div></div>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {filteredBatches.map((batch, index) => (
                        <tr key={index} className="td-hover">
                          {batch.batchNumber ? (
                            <td className="text-center">{index + 1}</td>
                          ) : (
                            <td> </td>
                          )}
                          <td className="text-center">{batch.batchNumber}</td>
                          <td className="text-center">
                            {batch.resettlementSiteName}
                          </td>
                          <td className="text-center">{batch.projectName}</td>
                          <td className="text-center">
                            {batch.beneficiaryCount}
                          </td>
                          <td className="text-center">
                            {batch.batchNumber && accessLevel == 2 ? (
                              <Link>
                                <BsPrinter
                                  onClick={() =>
                                    openPrintModal(batch.batchNumber)
                                  }
                                />
                              </Link>
                            ) : null}
                          </td>
                          {accessLevel == 2 ? (
                            <td className="text-center">
                              {batch.batchNumber ? (
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "blue",
                                  }}
                                  to={`/batchdetails/${batch.batchNumber}`}
                                >
                                  Details
                                </Link>
                              ) : null}
                            </td>
                          ) : (
                            <div></div>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div
                  className="card-footer"
                  style={{
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  {/* <div className="row cardFooterCenter">
                                        <div className="col-sm-6">
                                            <label>1</label>{" "}
                                            <span className="text-muted">
                                                of
                                            </span>{" "}
                                            <label>3</label> &nbsp;
                                            <IconButton>
                                                <ArrowBackIosIcon className="text-md pl-1" />
                                            </IconButton>
                                            <IconButton>
                                                <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                            </IconButton>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={isPrintModalOpen}
        onClose={closePrintModal}
        batchNumber={formData.modalBatchNumber}
        formData={formData}
        inRequest={false}
        isBatchPage={true} // Set this prop to true
      >
        <h2>Print MLQH-RRS</h2>
        <div>
          <label className="mr-3" htmlFor="preparedBy">
            Prepared by:
          </label>
          <input
            className={"mt-1"}
            value={formData.preparedBy}
            onChange={handleChange}
            type="text"
            id="preparedBy"
            name="preparedBy"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="designation">
            Designation:
          </label>
          <input
            className={"mt-1"}
            value={formData.designation}
            onChange={handleChange}
            type="text"
            id="designation"
            name="designation"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="reviewedBy">
            Reviewed By:
          </label>
          <input
            className={"mt-1"}
            value={formData.reviewedBy}
            onChange={handleChange}
            type="text"
            id="reviewedBy"
            name="reviewedBy"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="designation2">
            Designation
          </label>
          <input
            className={"mt-1"}
            value={formData.designation2}
            onChange={handleChange}
            type="text"
            id="designation2"
            name="designation2"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="certifiedCorrect">
            Certified Correct:
          </label>
          <input
            className={"mt-1"}
            value={formData.certifiedCorrect}
            onChange={handleChange}
            type="text"
            id="certifiedCorrect"
            name="certifiedCorrect"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="designation3">
            Designation:
          </label>
          <input
            className={"mt-1"}
            value={formData.designation3}
            onChange={handleChange}
            type="text"
            id="designation3"
            name="designation3"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="approvedBy">
            Approved by:
          </label>
          <input
            className={"mt-1"}
            value={formData.approvedBy}
            onChange={handleChange}
            type="text"
            id="approvedBy"
            name="approvedBy"
          />
        </div>
        <div>
          <label className="mr-3" htmlFor="designation4">
            Designation:
          </label>
          <input
            className={"mt-1"}
            value={formData.designation4}
            onChange={handleChange}
            type="text"
            id="designation4"
            name="designation4"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Batch;
