import React, { useState, useEffect, useCallback, isValidElement } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { BsUpload } from "react-icons/bs";
import UploadPdf from "./UploadPdf";
import axios from "axios";
import Cookies from "js-cookie";
import fxaddUsers from "./fx_Users";
import { toast, ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const label = { inputProps: { "aria-label": "Switch demo" } };
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: "100px",
      height: "100px",
      fontSize: "300%",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const Users = () => {
  // mui
  const selectLabelStyle = {
    fontWeight: "normal",
  };
  const labelStyle = {
    fontWeight: "normal",
  };

  // stepper for edit user
  const [currentStep, setCurrentStep] = useState(1);

  // stepper add user
  const [currentAddUser, setCurrentAddUser] = useState(1);
  const [formAdd, setFormAdd] = useState({});

  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // mui select

  axios.defaults.withCredentials = true;
  // popup
  const [positions, setPositions] = useState([]);
  const [openModal, setopenModal] = useState(false);

  const handleclose = () => setopenModal(false);

  // modal

  // filter
  const [openFilter, setopenFilter] = useState(false);
  const filterMenu = () => setopenFilter(!openFilter);

  // popper
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const toggleDropdown = () => {
    setIsPopperOpen(!isPopperOpen);
  };
  const openDropdown = () => {
    setIsPopperOpen(true);
  };

  const [selectedTableUser, setSelectedTableUser] = useState(1);

  const userAccessLevel = Cookies.get("accessLevel");

  const generatePassword = (length) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return "NHA" + password;
  };

  const initialFormData = {
    id: null,
    accessLevel: "",
    regionalOfficeID: null, // Change from regionalOffice to regionalOfficeID
    districtOfficeID: null,
    regionalOffice: "",
    districtOffice: "",
    positionName: "",
    employeeNo: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: generatePassword(5),
    mobileNo: "",
    email: "",
    status: "0",
    signature: null,
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [passwordEnable, setPasswordEnable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedRegionalOffice, setSelectedRegionalOffice] = useState();
  const [selectedDistrictOffice, setSelectedDistrictOffice] = useState();
  const [regionalOffices, setRegionalOffices] = useState([]);
  const [regionalOfficesID, setRegionalOfficesID] = useState([]);
  const [districtOffices, setDistrictOffices] = useState([]);
  const [users, setUsers] = useState([null]);
  const [userListUpdated, setUserListUpdated] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredDistrictOffices, setFilteredDistrictOffices] = useState([]);
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");
  const accessLevel = Cookies.get("accessLevel");

  const handleUserClick = (user) => {
    setSelectedTableUser(user); // Set the selected user in the state
  };

  // const fetchOffices = useCallback(async () => {
  //     try {
  //         const regionalOfficesResponse = await axios.get(
  //             "https://op.apps.nha.gov.ph/api/bpro"
  //         );
  //         const regionalOfficesData = regionalOfficesResponse.data.data;
  //         console.log("regional offices data:", regionalOfficesData);

  //         const districtOfficesResponse = await axios.get(
  //             "https://op.apps.nha.gov.ph/api/bpdo"
  //         );
  //         const districtOfficesData = districtOfficesResponse.data.data;
  //         console.log("district offices data:", districtOfficesData);

  //         const groupedData = regionalOfficesData.map((region) => ({
  //             ...region,
  //             districtOffices: districtOfficesData.filter(
  //                 (district) => district.BP_ROID === region.BP_ID
  //             ),
  //         }));
  //         console.log("grouped data:", groupedData);

  //         setRegionalOffices(groupedData);
  //         setDistrictOffices(districtOfficesData);

  //         if (formData.regionalOfficeID) {
  //             const filteredOffices = districtOfficesData.filter(
  //                 (office) => office.BP_ROID === formData.regionalOfficeID
  //             );
  //             setFilteredDistrictOffices(filteredOffices);
  //         } else {
  //             setFilteredDistrictOffices([]);
  //         }
  //     } catch (error) {
  //         console.error("Error fetching offices:", error);
  //     }
  // }, [formData.regionalOfficeID]);

  // useEffect(() => {
  //     fetchOffices();
  // }, [fetchOffices, formData.regionalOfficeID]);

  function toProperCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const modtoggle = (user) => {
    setSelectedUser(user);
    setopenModal(!openModal);
  };
  const handleRegionalOfficeSelect = async (regionalOfficeId) => {
    try {
      // Send selected regional office ID to the backend
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_district_office_fetch_by_regional_office",
        { regionalOfficeId }
      );

      // Handle response and update state with filtered district offices
      setDistrictOffices(response.data);
    } catch (error) {
      console.error("Error fetching district offices:", error);
      // Handle error
    }
  };
  const onSelectChange = (event) => {
    const regionalOfficeId = parseInt(event.target.value);

    if (regionalOfficeId) {
      const selectedOffice = regionalOffices.find(
        (office) => office.id === regionalOfficeId
      );

      if (selectedOffice) {
        setFormData((prevData) => ({
          ...prevData,
          regionalOfficeID: regionalOfficeId, // Update regionalOfficeID
          regionalOffice: selectedOffice.regionalOffice, // Update regionalOffice
          selectedRegionalOffice: regionalOfficeId, // Update selectedRegionalOffice
        }));

        // Handle the change of regional office
        handleRegionalOfficeSelect(regionalOfficeId);
      }
    }
  };

  const onSelectChangeDistrict = (event) => {
    const districtOfficeId = parseInt(event.target.value);

    if (districtOfficeId) {
      const selectedDO = districtOffices.find(
        (office) => office.id === districtOfficeId
      );

      if (selectedDO) {
        setFormData((prevData) => ({
          ...prevData,
          districtOfficeID: districtOfficeId, // Set districtOfficeID
          districtOffice: selectedDO.districtOffice, // Set districtOffice
          selectedDistrictOffice: districtOfficeId, // Update selectedDistrictOffice
        }));
      }
    }
  };

  const fetchRegion = () => {
    axios
      .get("https://api.nha.gov.ph/ep/trx_regional_district_office_fetch")
      .then((response) => {
        setRegionalOffices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching offices:", error);
      });
  };

  useEffect(() => {
    fetchRegion();
  }, []);

  const handleCheckboxChange = () => {
    setCheckPass((prevState) => !prevState);
    setFormData((prevData) => {
      if (!prevData.password || passwordEnable) {
        return {
          ...prevData,
          password: generatePassword(5),
        };
      } else {
        return {
          ...prevData,
          password: "",
        };
      }
    });
    setPasswordEnable(checkPass);
  };

  //pagination
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const displayedUsers = users.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const totalPages = Math.ceil(users.length / ITEMS_PER_PAGE);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //SOFT DELETE
  const handleStatusChange = async (user) => {
    try {
      const updatedUser = { ...user, status: 1 };
      await axios.put(
        `https://api.nha.gov.ph/ep/trx_updateUser/${user.id}`,
        updatedUser
      );
      setUserListUpdated((prev) => !prev);
    } catch (error) {
      console.error("Failed to update user status:", error);
    }
    handleclose();
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`https://api.nha.gov.ph/ep/getallusers`);
      const usersData = response.data;
      setUsers(usersData.users);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let properCaseValue = value;
    if (["firstName", "middleName", "lastName"].includes(name)) {
      properCaseValue = toProperCase(value);
    }

    if (name === "accessLevel" && !isEditMode) {
      setRegionalOffices([]);
      setDistrictOffices([]);
      fetchRegion();
      setFormData((prevData) => ({
        ...prevData,
        [name]: properCaseValue,
        regionalOffice: null, // Clear regional office value when access level changes
        districtOffice: null, // Clear district office value when access level changes
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: properCaseValue,
      }));
    }
  };

  // const handleRegionalOfficeChange = (event) => {
  //     const selectedROID = event.target.value;
  //     const selectedROName = event.target.selectedOptions[0].text;

  //     setFormData({
  //         ...formData,
  //         regionalOfficeID: selectedROID,
  //         regionalOffice: selectedROName,
  //         districtOffice: "",
  //         districtOfficeID: "",
  //     });

  //     if (selectedROID) {
  //         const filteredOffices = districtOffices.filter(
  //             (office) => office.BP_ROID === selectedROID
  //         );
  //         setFilteredDistrictOffices(filteredOffices);
  //     } else {
  //         setFilteredDistrictOffices([]);
  //     }
  // };
  const handleEditClick = async (user) => {
    setIsEditMode(true);
    openDropdown();
    fetchRegion();
    // Fetch district offices based on the user's regional office ID
    await fetchDistrictOffices(user.regionalOfficeID);
    // Set the selected regional office ID in the state
    setSelectedRegionalOffice(user.regionalOfficeID);

    // Set form data with user details

    // Set form data with user details
    setFormData((prevData) => ({
      ...prevData,
      id: user.id,
      accessLevel: user.accessLevel,
      regionalOfficeID: user.regionalOfficeID,
      districtOfficeID: user.districtOfficeID,
      regionalOffice: user.regionalOffice, // Include regionalOffice here
      districtOffice: user.districtOffice,
      positionName: user.positionName,
      employeeNo: user.employeeNo,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      password: "",
      mobileNo: user.mobileNo,
      email: user.email,
      status: user.status,
    }));

    setSelectedDistrictOffice(user.districtOfficeID);
  };

  const fetchDistrictOffices = async (regionalOfficeId) => {
    try {
      // Fetch district offices based on the selected regional office ID
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_district_office_fetch_by_regional_office",
        { regionalOfficeId }
      );
      // Set the fetched district offices in the state
      setDistrictOffices(response.data);
    } catch (error) {
      console.error("Error fetching district offices:", error);
      // Handle error
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!/^\d{5,7}$/.test(formData.employeeNo)) {
      newErrors.employeeNo = "Employee number must be 5 to 7 digits";
      isValid = false;
    }

    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
      isValid = false;
    } else if (!/^[a-zA-Z-\s]+$/.test(formData.firstName)) {
      newErrors.firstName = "First name must contain only letters";
      isValid = false;
    }

    if (formData.middleName && !/^[a-zA-Z-\s]+$/.test(formData.middleName)) {
      newErrors.middleName =
        "Middle name must contain only letters and hyphens";
      isValid = false;
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
      isValid = false;
    } else if (!/^[a-zA-Z-\s]+$/.test(formData.lastName)) {
      newErrors.lastName = "Last name must contain only letters";
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!/^\d{11}$/.test(formData.mobileNo)) {
      newErrors.mobileNo = "Mobile number must be 11 digits";
      isValid = false;
    }

    if (!formData.accessLevel) {
      newErrors.accessLevel = "Access Level is required";
      isValid = false;
    }

    if (!formData.positionName) {
      newErrors.positionName = "Position is required";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handlePasswordGeneration = useCallback(() => {
    if (!isEditMode) {
      setFormData((prevData) => ({
        ...prevData,
        password: generatePassword(5),
      }));
    }
  }, [isEditMode]);

  useEffect(() => {
    handlePasswordGeneration();
  }, [handlePasswordGeneration]);

  const addUsers = async () => {
    try {
      if (validateForm()) {
        if (formData.id) {
          let accessLevelConvert = "";
          switch (formData.accessLevel) {
            case "RDSD":
              accessLevelConvert = 0;
              break;
            case "RDSD - (Recommending Approval)":
              accessLevelConvert = 0.1;
              break;
            case "RDSD - (Approved by)":
              accessLevelConvert = 0.2;
              break;
            case "Regional Office":
              accessLevelConvert = 1.0;
              break;
            case "Regional Office - (Recommending Approval)":
              accessLevelConvert = 1.1;
              break;
            case "Regional Office - (Approved by)":
              accessLevelConvert = 1.2;
              break;
            case "District Office":
              accessLevelConvert = 2;
              break;
              default:
              toast.error("Error");
              break
          }
          const userDataToUpdate = {
            ...formData,
            accessLevel: accessLevelConvert,
            // regionalOfficeID:
            //     formData.regionalOffice.regionalOffice,
          };
          if (!formData.password) {
            delete userDataToUpdate.password;
          }
          toast.success("Success");
          console.log("usertoupdate", userDataToUpdate);
          await axios.put(
            `https://api.nha.gov.ph/ep/trx_update/${formData.id}`,
            userDataToUpdate
          );
        } else {
          // Find regional office by regionalOfficeID
          const foundRegionalOffice = regionalOffices.find(
            (office) => office.id === formData.regionalOfficeID
          );
          // Find district office by districtOfficeID
          const foundDistrictOffice = districtOffices.find(
            (office) => office.id === formData.districtOfficeID
          );
          console.log("formData before submitting", formData);
          const response = await fxaddUsers(
            formData.accessLevel,
            formData.districtOfficeID,
            formData.regionalOfficeID,
            formData.regionalOffice, // Include regionalOffice if available
            formData.districtOffice, // Include districtOffice if available
            formData.positionName,
            formData.employeeNo,
            formData.firstName,
            formData.middleName ? formData.middleName : "",
            formData.lastName,
            formData.password,
            formData.mobileNo,
            formData.email,
            formData.status,
            formData.signature
          );

          if (response === "0000") {
            toast.success("Success");
            await fetchUsers(); // Fetch the latest user data
          } else {
            toast.error("Failed to create user!");
            return;
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = errors;

    if (validateForm()) {
      handlePasswordGeneration();
      await addUsers();

      setFormData((prevData) => ({
        ...initialFormData,

        password: isEditMode ? "" : generatePassword(5),
        // regionalOfficeID:
        //     prevData.accessLevel === "" ||
        //     prevData.accessLevel === "RDSD"
        //         ? null
        //         : prevData.regionalOfficeID,
        regionalOffice:
          prevData.accessLevel === "" || prevData.accessLevel === "RDSD"
            ? ""
            : prevData.regionalOffice,
      }));
      setCurrentStep(currentStep - 1);
      resetForm();
      setFilteredDistrictOffices([]);
      setIsEditMode(false);
      handlePasswordGeneration();
      setOpen(false);
      fetchUsers()
    }
  };

  const handleCheckboxChangeDelete = useCallback((id) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, selected: !user.selected } : user
      )
    );
  }, []);

  const handleDeleteSelected = () => {
    setopenModal(true);
  };

  const handleCloseModal = () => {
    setopenModal(false);
  };

  const handleDeleteUsers = async () => {
    try {
      const selectedUsers = users.filter((user) => user.selected);

      for (const user of selectedUsers) {
        const updatedUser = { ...user, status: 1 };
        await axios.put(
          `https://api.nha.gov.ph/ep/trx_updateUser/${user.id}`,
          updatedUser
        );
        // setopenModal(true);
      }

      setUserListUpdated((prev) => !prev);
      setSelectedTableUser(1);
      setopenModal(false);
    } catch (error) {
      console.error("Failed to update status of selected users:", error);
    }
  };

  const resetForm = () => {
    setRegionalOffices([]);
    setFormData((prevData) => ({
      ...initialFormData,
      regionalOffice: "",
      regionalOfficeID: "",
      districtOffice: "",
      districtOfficeID: "",
    }));
  };
  useEffect(() => {
    switch (formData.accessLevel) {
      case "RDSD":
        setPositions([
          "Sr. RD Officer A",
          "Sr. RD Officer B",
          "RD Chief",
          "Division Manager, RRMD",
          "Department Manager, RDSD",
        ]);
        break;
      case "RDSD - (Recommending Approval)":
        setPositions(["RD Chief", "Division Manager, RRMD"]);
        break;
      case "RDSD - (Approved by)":
        setPositions(["Department Manager"]);
        break;
      case "Regional Office":
        setPositions([
          "Sr. RD Officer A",
          "RD Chief",
          "Program Manager",
          "Regional Manager",
        ]);
        break;
      case "Regional Office - (Approved by)":
        setPositions(["Regional Manager"]);
        break;
      case "Regional Office - (Recommending Approval)":
        setPositions(["Sr. RD Officer A", "RD Chief", "Division Manager"]);
        break;
      case "District Office":
        setPositions(["EMU (Chief)", "CSSU (Chief)"]);
        break;
      default:
        setPositions([]);
        break;
    }
  }, [formData.accessLevel]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Check if a file is selected
    if (selectedFile) {
      console.log("signature set");
      setFormData((prevFormData) => ({
        ...prevFormData,
        signature: selectedFile, // Set the File object directly in formData
      }));
      setFileName(selectedFile.name);
    }

    // Optional: handle any errors
    if (event.target.files.length === 0) {
      console.error("No file selected.");
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          sx={style}
          className="modal-viewInfo card userModal"
          style={{ height: "80%", borderRadius: "15px" }}
        >
          <div className="card-header">
            <div className="card-title font-weight-bold">Add User</div>
          </div>

          <div className="card-body">
            {currentAddUser === 1 && (
              <div>
                <div style={{ lineHeight: "15px" }}>
                  <h3 className="mb-1 font-weight-bold">User info</h3>
                  <small className="text-muted">
                    Verify the precision and validity of the information <br />{" "}
                    subject to modification
                  </small>
                </div>

                <TextField
                  className="mb-4 mt-4"
                  id="outlined-basic"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  label="First Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleInputChange}
                  label="Middle Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                />
                <TextField
                  className="mb-0"
                  id="outlined-basic"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <small className="float-right mb-3">
                  *Please use an active Email Address
                </small>
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  label="Employee Number"
                  name="employeeNo"
                  value={formData.employeeNo}
                  onChange={handleInputChange}
                  placeholder="Enter Employee Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  error={!!errors.employeeNo}
                  helperText={errors.employeeNo}
                />
                <TextField
                  className="mb-4"
                  id="outlined-basic"
                  label="Mobile Number"
                  name="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleInputChange}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  error={!!errors.mobileNo}
                  helperText={errors.mobileNo}
                />
              </div>
            )}

            {currentAddUser === 2 && (
              <div>
                <div style={{ lineHeight: "15px" }}>
                  <h3 className="mb-1 font-weight-bold">Account info</h3>
                  <small className="text-muted">
                    Verify the precision and validity of the information <br />{" "}
                    subject to modification
                  </small>
                </div>

                <FormControl
                  className="mt-4"
                  size="small"
                  fullWidth
                  error={!!errors.accessLevel}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={selectLabelStyle}
                  >
                    Access Level
                  </InputLabel>
                  <Select
                    labelId=""
                    name="accessLevel"
                    defaultValue=""
                    id=""
                    value={formData.accessLevel}
                    label="Access Level"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="RDSD">RDSD</MenuItem>
                    <MenuItem value="RDSD - (Recommending Approval)">
                      RDSD - (Recommending Approval)
                    </MenuItem>
                    <MenuItem value="RDSD - (Approved by)">
                      RDSD - (Approved by)
                    </MenuItem>
                    <MenuItem value="Regional Office">Regional Office</MenuItem>
                    <MenuItem value="Regional Office - (Recommending Approval)">
                      Regional Office - (Recommending Approval)
                    </MenuItem>
                    <MenuItem value="Regional Office - (Approved by)">
                      Regional Office - (Approved by)
                    </MenuItem>
                    <MenuItem value="District Office">District Office</MenuItem>
                  </Select>
                  {errors.accessLevel && (
                    <FormHelperText>{errors.accessLevel}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className="mt-4"
                  size="small"
                  fullWidth
                  error={!!errors.regionalOffice}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={selectLabelStyle}
                  >
                    Regional Office
                  </InputLabel>
                  <Select
                    labelId="regional-office-label"
                    id="regionalOffice"
                    name="regionalOffice"
                    value={formData.regionalOfficeID || ""}
                    onChange={onSelectChange}
                    disabled={!formData.accessLevel.includes("Office")}
                  >
                    <MenuItem value="">Select Regional Office</MenuItem>
                    {regionalOffices.map((office) => (
                      <MenuItem key={office.id} value={office.id}>
                        {office.regionalOffice}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.accessLevel && (
                    <FormHelperText>{errors.regionalOffice}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  size="small"
                  className="mt-4"
                  fullWidth
                  error={!!errors.districtOffice}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={selectLabelStyle}
                  >
                    District Office
                  </InputLabel>
                  <Select
                    labelId=""
                    id="districtOffice"
                    name="districtOffice"
                    value={formData.districtOfficeID}
                    onChange={onSelectChangeDistrict}
                    disabled={
                      !formData.accessLevel.includes("Office") ||
                      !formData.accessLevel.includes("District")
                    }
                  >
                    <MenuItem value="">Select District Office</MenuItem>
                    {districtOffices.map((office) => (
                      <MenuItem key={office.id} value={office.id}>
                        {office.districtOffice}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.districtOffice && (
                    <FormHelperText>{errors.districtOffice}</FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  size="small"
                  className="mt-4"
                  fullWidth
                  error={!!errors.positionName}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={selectLabelStyle}
                  >
                    Position
                  </InputLabel>
                  <Select
                    labelId=""
                    id="positionName"
                    name="positionName"
                    value={formData.positionName}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">Select Position</MenuItem>
                    {positions.map((position, index) => (
                      <MenuItem key={index} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.positionName && (
                    <FormHelperText>{errors.positionName}</FormHelperText>
                  )}
                </FormControl>

                <TextField
                  className="mt-4"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <div className="text-xs text-muted mt-1">
                  Create a password that:
                  <li>contains at least 8 characters</li>
                  <li>
                    contains both lower (a-z) and upper case letters (A-Z)
                  </li>
                  <li>contains at least one number (0-9) or a symbol</li>
                  <li>is not commonly used</li>
                </div>
                {formData.accessLevel == "Regional Office - (Approved by)" && (
                  <>
                    <Input
                      type="file"
                      inputProps={{ accept: "image/*" }} // To accept only image files
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="upload-signature"
                    />
                    <label htmlFor="upload-signature">
                      <BsUpload /> {"          "}
                      Upload Signature
                    </label>
                    {fileName && (
                      <small className="text-muted">
                       {" "} {fileName} uploaded
                      </small>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="card-footer bg-transparent">
            {currentAddUser === 1 && (
              <div className="mb-2">
                <Button
                  variant="contained"
                  className="w-100 p-2 text-dark"
                  onClick={() => setCurrentAddUser(currentAddUser + 1)}
                  style={{ backgroundColor: "#2be0a9" }}
                >
                  Next &nbsp; <BsIcons.BsArrowRight className="text-lg" />
                </Button>
              </div>
            )}

            {currentAddUser === 2 && (
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  className="p-2 text-dark"
                  onClick={() => setCurrentAddUser(currentAddUser - 1)}
                  style={{
                    width: "47%",
                    border: "2px solid #2be0a9",
                    color: "#24dc8f",
                  }}
                >
                  <BsIcons.BsArrowLeft /> &nbsp; Previous
                </Button>
                <Button
                  variant="contained"
                  className="p-2 text-dark"
                  onClick={handleSubmit}
                  style={{
                    width: "47%",
                    backgroundColor: "#2be0a9",
                  }}
                >
                  Submit{" "}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <div className="content-wrapper pl-2 pr-2">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Users</h1>
              </div>
              <div className="col-sm-6">
                {/* <h1 className='float-right'><Button variant="contained" style={{borderRadius: '50px', backgroundColor: '#2be0a9', color: '#292726'}}><BsIcons.BsPlusLg />&nbsp;Add User</Button></h1> */}
                <div className="float-right" style={{ display: "flex" }}>
                  <Tooltip title="Add User">
                    <IconButton onClick={handleOpen}>
                      <AddCircleIcon
                        className="text-xl"
                        style={{ color: "#34a8ff" }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-3 pr-3">
          <div className="row">
            <div className="col-sm-4">
              <div
                className="card"
                style={{
                  borderTop: "4px solid #292726",
                  borderRadius: "15px",
                }}
              >
                <div className="card-body">
                  {currentStep === 1 && (
                    <div>
                      <div>
                        {currentStep === 1 && (
                          <Tooltip title="Edit">
                            <IconButton
                              className="float-right"
                              aria-label="delete"
                              onClick={() => {
                                setCurrentStep(currentStep + 1);
                                handleEditClick(selectedTableUser);
                              }}
                            >
                              <BorderColorIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                      <div className="text-center pt-2 pb-3">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {selectedTableUser &&
                            selectedTableUser.firstName &&
                            selectedTableUser.lastName && (
                              <Avatar
                                {...stringAvatar(
                                  `${selectedTableUser.firstName} ${selectedTableUser.lastName}`
                                )}
                              />
                            )}
                        </div>
                        <h4 className="mt-3 mb-0 font-weight-bold">
                          {selectedTableUser.firstName}{" "}
                          {/* {
                                                        selectedTableUser
                                                            .middleName[0]
                                                    } */}{" "}
                          {selectedTableUser.lastName}
                        </h4>
                        <small>{selectedTableUser.email}</small>
                      </div>
                      <ul class="list-group list-group-unbordered mb-3">
                        <li class="list-group-item border-top-0">
                          <span className="text-muted">Employee No.</span>{" "}
                          <span class="float-right">
                            {selectedTableUser.employeeNo}
                          </span>
                        </li>
                        <li class="list-group-item border-top-0">
                          <span className="text-muted">Position</span>{" "}
                          <span class="float-right">
                            {selectedTableUser.positionName}
                          </span>
                        </li>
                        <li class="list-group-item border-top-0">
                          <span className="text-muted">Access Level</span>{" "}
                          <span class="float-right">
                            {selectedTableUser.accessLevel}
                          </span>
                        </li>
                        <li class="list-group-item border-top-0">
                          <span className="text-muted">Region</span>{" "}
                          <span class="float-right">
                            {selectedTableUser.regionalOffice}
                          </span>
                        </li>
                        <li class="list-group-item border-top-0">
                          <span className="text-muted">District</span>{" "}
                          <span class="float-right">
                            {selectedTableUser.districtOffice}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div>
                      <div className="text-left mb-4">
                        {currentStep === 2 && (
                          <small>
                            <a
                              // href="#"
                              onClick={() => setCurrentStep(currentStep - 1)}
                              style={{
                                color: "#34a8ff",
                              }}
                            >
                              <BsIcons.BsArrowLeft
                                className="mb-1"
                                style={{
                                  color: "#34a8ff",
                                }}
                              />{" "}
                              <b>Cancel</b>
                            </a>
                          </small>
                        )}
                      </div>
                      <div
                        className="text-left mb-3 mt-2"
                        style={{ lineHeight: "10px" }}
                      >
                        <h4 className="font-weight-bold">Edit Account User</h4>
                        <small>Please input correct and honest details</small>
                      </div>
                      <div style={{ display: "flex" }}>
                        <span className="float-right">
                          <span className="font-weight-bold">Active</span>{" "}
                          <Switch
  {...label}
  checked={selectedTableUser.status == 0}
  className="mb-1"
  onChange={() => {
    const newStatus = selectedTableUser.status == 0 ? 1 : 0;
    setSelectedTableUser((prevUser) => ({
      ...prevUser,
      status: newStatus,
    }));
    setFormData((prevData) => ({
      ...prevData,
      status: newStatus,
    }));
  }}
/>
                        </span>
                      </div>

                      <TextField
                        className="mb-4"
                        id="outlined-basic"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        label="First Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Enter First Name"
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                      />
                      <TextField
                        className="mb-4"
                        id="outlined-basic"
                        name="lastName"
                        value={formData.lastName}
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        onChange={handleInputChange}
                      />
                      <TextField
                        className="mb-4"
                        id="outlined-basic"
                        name="middleName"
                        value={formData.middleName}
                        label="Middle Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        onChange={handleInputChange}
                      />
                      <Select
                        className="mb-4"
                        labelId=""
                        id="positionName"
                        size="small"
                        name="positionName"
                        value={formData.positionName}
                        onChange={handleInputChange}
                        // disabled={
                        //     !(
                        //         formData.accessLevel &&
                        //         (formData.accessLevel.includes(
                        //             "District Office"
                        //         ) ||
                        //             formData.accessLevel.includes(
                        //                 "Regional Office"
                        //             ))
                        //     )
                        // }
                      >
                        <MenuItem value="">Select Position</MenuItem>
                        {positions.map((position, index) => (
                          <MenuItem key={index} value={position}>
                            {position}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        className="mb-4"
                        id="outlined-basic"
                        name="mobileNo"
                        value={formData.mobileNo}
                        label="Contact Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="number"
                        onChange={handleInputChange}
                      />
                      <TextField
                        className="mb-0"
                        id="outlined-basic"
                        name="email"
                        value={formData.email}
                        label="Email Address"
                        variant="outlined"
                        size="small"
                        fullWidth
                        // disabled={isEditMode}
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="email"
                        onChange={handleInputChange}
                      />
                      <small className="float-right mb-3">
                        *Please use an active Email Address
                      </small>
                      <TextField
                        className="mb-0"
                        id="outlined-basic"
                        name="password"
                        value={formData.password}
                        label="Password"
                        variant="outlined"
                        size="small"
                        onChange={handleInputChange}
                        fullWidth
                        disabled={isEditMode && !checkPass}
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                      />
                      <div>
                        <label>New Password</label>
                        <input
                          className="m-2"
                          type="checkbox"
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="w-100 mt-4 text-dark"
                        size="large"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#2be0a9",
                        }}
                      >
                        SAVE CHANGES
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              <div
                className="card col-sm-12"
                style={{
                  borderTop: "4px solid #292726",
                  borderRadius: "15px",
                }}
              >
                <div className="card-header border-0 mt-1">
                  <h3 className="card-title font-weight-bold">
                    List of System Users
                  </h3>
                  <div className="card-tools">
                    <div
                      className="input-group input-group-sm"
                      style={{ width: 200 }}
                    >
                      <input
                        type="text"
                        name="table_search"
                        className="form-control float-right"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-default">
                          <BsIcons.BsSearch />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card-body table-responsive p-0"
                  style={{ height: 400 }}
                >
                  <table className="table table-head-fixed table-hover text-wrap">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                        <th>Office</th>
                        <th>Email address</th>
                        <th className="text-center">Access Level</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {users && users.length > 0 ? (
                        users.map((user, index) => {
                          // Check if user exists, return null or a fallback message if it's null
                          if (!user) {
                            return (
                              <tr key={index}>
                                <td colSpan="5">User data is missing</td>
                              </tr>
                            );
                          }

                          return (
                            <tr
                              key={user.id || index} // Fallback to index if user.id is not available
                              onClick={() => handleUserClick(user)}
                            >
                              <td>{index + 1}</td>
                              <td>
                                {/* Check if each name field exists */}
                                {user.firstName ? user.firstName : "N/A"}{" "}
                                {user.middleName
                                  ? `${user.middleName[0]}.`
                                  : ""}{" "}
                                {user.lastName ? user.lastName : "N/A"}
                              </td>
                              <td>{user.positionName ? user.positionName : "N/A"}</td>
                              <td>
  {user.districtOffice ?? user.regionalOffice ?? "RDSD"}
</td>
                              <td>{user.email ? user.email : "N/A"}</td>
                              <td className="text-center">
                                <label
                                  className="p-1 text-sm w-100"
                                  style={{
                                    border: `2px solid ${
                                      user.accessLevel.includes("District")
                                        ? "#0000FF" // Orange for District
                                        : user.accessLevel.includes("Regional")
                                        ? "#2be0a9" // Green for Regional
                                        : user.accessLevel.includes("RDSD")
                                        ? "#FF0000" // Red for RDSD
                                        : "#000000" // Default color if none match
                                    }`,
                                    borderRadius: "50px",
                                    color: user.accessLevel.includes("District")
                                      ? "#0000FF"
                                      : user.accessLevel.includes("Regional")
                                      ? "#24dc8f"
                                      : user.accessLevel.includes("RDSD")
                                      ? "#FF0000"
                                      : "#000000",
                                  }}
                                >
                                  {user.accessLevel ? user.accessLevel : "N/A"}
                                </label>
                              </td>
                              <td>
                                <Tooltip title={"Active"}>
                                  <p className="text-center">
                                    <BsIcons.BsCircleFill
                                      style={{
                                        color: "#24dc8f",
                                      }}
                                    />
                                  </p>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">No users available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Users;
