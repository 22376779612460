import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import Cookies from "js-cookie";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
};
function ResettlementSite(props) {
  const initialFormData = {
    // id: uuidv4(),
    resettlementSiteName: "",
    regionAddr: "",
    regionCode: "",
    provinceAddr: "",
    provinceCode: 0,
    cityAddr: "",
    cityCode: 0,
    barangayAddr: "",
    barangayCode: 0,
    chargeability: "",
  };
  const selectLabelStyle = {
    fontWeight: "normal",
  };
  const labelStyle = {
    fontWeight: "normal",
  };
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  useEffect(() => {
    const user = Cookies.get("userId");
    setUserId(user);
  });
  const [formData, setFormData] = useState(initialFormData);
  // accordion
  const [openModal, setopenModal] = useState(false);
  const modtoggle = () => setopenModal(!openModal);

  const handleclose = () => setopenModal(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const [regionAddr, setRegionAddr] = useState("");
  const [provinceAddr, setProvinceAddr] = useState("");
  const [cityAddr, setCityAddr] = useState("");
  const [barangayAddr, setBarangayAddr] = useState("");
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const location = useLocation();
  const selectedIdFromEntryPass = location.state ? location.state.id : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page
  const [filterData, setFilterData] = useState();
  const [regionalOffices, setRegionalOffices] = useState([]);
  const [districtOffices, setDistrictOffices] = useState([]);

  // Calculate the index of the last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Get the current items to display based on the calculated indexes
  const currentItems = filterData
    ? data
    : data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Function to change the current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleRowClick = (id) => {
    // Check if the clicked row is already selected
    if (selectedId === id) {
      // If yes, unselect the row
      setSelectedId(null);
    } else {
      // If no, select the row
      setSelectedId(id);
    }
  };
  const [submitClicked, setSubmitClicked] = useState(false);

  // useEffect(() => {
  //     axios
  //         .get("https://api.nha.gov.ph/ep/trx_regional_district_office_fetch")
  //         .then((response) => {
  //             setRegionalOffices(response.data);
  //             console.log("regionalO", response.data);

  //             if (submitClicked) {
  //                 setSubmitClicked(false); // Reset submitClicked to false
  //             }
  //         })
  //         .catch((error) => {
  //             console.error("Error fetching offices:", error);
  //         });
  // }, [submitClicked]);
  // const handleRegionalOfficeSelect = async (regionalOfficeId) => {
  //     try {
  //         const response = await axios.post(
  //             "https://api.nha.gov.ph/ep/trx_district_office_fetch_by_regional_office",
  //             { regionalOfficeId }
  //         );
  //         console.log("DIDIDIDI", response);

  //         setDistrictOffices(response.data);
  //     } catch (error) {
  //         console.error("Error fetching district offices:", error);
  //     }
  // };
  // const onSelectChange = (event) => {
  //     const regionalOfficeId = parseInt(event.target.value); // Parse the value to ensure it's a number

  //     console.log("llllllll", event.target.value);
  //     const selectedRO = regionalOffices.find(
  //         (office) => office.id === regionalOfficeId
  //     );
  //     console.log("mmmmm", selectedRO.regionalOffice);

  //     setFormData({
  //         ...formData,
  //         regionalOffice: selectedRO.regionalOffice,
  //     });

  //     handleRegionalOfficeSelect(selectedRO.id);
  // };

  const resetForm = () => {
    setFormData(initialFormData);
    setRegion([]);
    setRegionAddr([]);
    setProvinceAddr([]);
    setCityAddr([]);
    setBarangayAddr([]);
    setProvince([]);
    setCity([]);
    setBarangay([]);
  };

  const region = () => {
    regions().then((response) => {
      const sortedResponse = response.sort(
        (a, b) => a.region_name.localeCompare(b.region_name) // Adjust the key if needed
      );
      setRegion(sortedResponse);
    });
  };

  const province = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = regionData.find(
      (item) => item.region_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.region_name;
      setFormData((prevState) => ({
        ...prevState,
        regionAddr: selectedText,
        regionCode: selectedValue,
      }));

      provinces(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.province_name.localeCompare(b.province_name) // Adjust the key if needed
          );
          setProvince(sortedResponse);
          setCity([]);
          setBarangay([]);
        })
        .catch((error) => {
          console.error("Error fetching provinces", error);
          // toast.error("Error fetching provinces");
        });
    }
  };

  const city = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = provinceData.find(
      (item) => item.province_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.province_name;
      setFormData((prevState) => ({
        ...prevState,
        provinceAddr: selectedText,
        provinceCode: selectedValue,
      }));

      cities(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.city_name.localeCompare(b.city_name) // Adjust the key if needed
          );
          setCity(sortedResponse);
          setBarangay([]);
        })
        .catch((error) => {
          console.error("Error fetching cities", error);
          // toast.error("Error fetching cities");
        });
    }
  };

  const barangay = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = cityData.find(
      (item) => item.city_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.city_name;
      setFormData((prevState) => ({
        ...prevState,
        cityAddr: selectedText,
        cityCode: selectedValue,
      }));

      barangays(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.brgy_name.localeCompare(b.brgy_name) // Adjust the key if needed
          );
          setBarangay(sortedResponse);
        })
        .catch((error) => {
          console.error("Error fetching barangays", error);
          // toast.error("Error fetching barangays");
        });
    }
  };

  const brgy = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = barangayData.find(
      (item) => item.brgy_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.brgy_name;
      setFormData((prevState) => ({
        ...prevState,
        barangayAddr: selectedText,
        barangayCode: selectedValue,
      }));
    }
  };

  useEffect(() => {
    region();
  }, []);

  const housingProgramOptions = [
    "AFP - PNP Housing Program",
    "BJMP - BFP Housing Project",
    "Danger Areas and Environs",
    "For ISFs Affected by Infrastructure Projects",
    "Housing Assistance Program for Calamity Victims (HAPCV)",
    "Housing Program for ISFs Affected by Supreme Court's Mandamus to Clean the Manila Bay Area",
    "Housing Program for ISFs Living Along Danger Areas (Other Regions)",
    "Housing Program for ISFs Living Along Danger Areas in Metro Manila",
    "New AFP - PNP Housing Program/Government Employees Housing Program",
    "North Triangle Relocation and Resettlement Project",
    "Resettlement Program for ISFs Affected by Department of Public Works and Highways Infrastructure Projects",
    "Resettlement Program for ISFs Affected by Department of Transportation Infrastructure Projects",
    "Resettlement Program for ISFs Living in Danger Areas in Bacoor, Cavite",
    "Others",
  ];
  const fetchResettlementSite = async () => {
    try {
      const response = await axios.get(
        "https://api.nha.gov.ph/ep/trx_resettlements"
      );
      const resettlements = response.data;
      setData(resettlements);
    } catch (error) {
      console.error("Error retrieving resettlements:", error);
    }
  };

  useEffect(() => {
    fetchResettlementSite();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "districtOffice") {
      const selectedDistrict = districtOffices.find(
        (district) => district.id === parseInt(value)
      );
      setFormData({
        ...formData,
        districtOffice: selectedDistrict.districtOffice,
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === "regionCode") {
      const selectedRegion = event.target.selectedOptions[0].text;
      setRegionAddr(selectedRegion);
    } else if (name === "provinceCode") {
      const selectedProvince = event.target.selectedOptions[0].text;
      setProvinceAddr(selectedProvince);
    } else if (name === "cityCode") {
      const selectedCity = event.target.selectedOptions[0].text;
      setCityAddr(selectedCity);
    } else if (name === "barangayCode") {
      const selectedBarangay = event.target.selectedOptions[0].text;
      setBarangayAddr(selectedBarangay);
    }
  }; // Clear error message for the corresponding field

  const validateForm = () => {
    let errors = {};

    if (!formData.resettlementSiteName) {
      errors.resettlementSiteName = "Resettlement site name is required.";
    }
    if (!formData.regionAddr) {
      errors.regionAddr = "Region is required.";
    }
    if (!formData.provinceAddr) {
      errors.provinceAddr = "Province is required.";
    }
    if (!formData.cityAddr) {
      errors.cityAddr = "City is required.";
    }
    if (!formData.barangayAddr) {
      errors.barangayAddr = "Barangay is required.";
    }
    if (!formData.chargeability) {
      errors.chargeability = "Chargeability is required.";
    }

    return errors;
  };
  useEffect(() => {
    if (formData.resettlementSiteName) {
      const filtered = data.filter((item) =>
        item.resettlementSiteName
          ?.toString()
          .toLowerCase()
          .includes(formData.resettlementSiteName.toLowerCase())
      );
      setCurrentPage(1); // Reset currentPage when filterData changes
      setData(filtered); // Update data to filtered data
    } else {
      fetchResettlementSite(); // Fetch all data if filter is empty
    }
  }, [formData.resettlementSiteName]); // Use formData.resettlementSiteName and data as dependencies

  // Function to change the current page
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    let chargeabilityValue = formData.chargeability;
    setRegion([]);

    // If "Others" is selected, use the custom input value
    if (formData.chargeability === "Others") {
      chargeabilityValue = `Others: ${formData.otherChargeability}`;
    }

    try {
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_resettlementsite",
        {
          ...formData,
          chargeability: chargeabilityValue,
        },
        { withCredentials: true }
      );

      resetForm();
      region();
      await fetchResettlementSite();
      toast.success("Resettlement Site Created Successfully");
    } catch (error) {
      // Check if it's a duplicate entry error (status code 409)
      if (error.response?.status === 409) {
        toast.error(
          error.response.data.message || "Duplicate resettlement site found"
        );
      } else {
        toast.error(
          error.response?.data?.message ||
            "Error creating Resettlement Site. Please try again."
        );
      }
      console.error("Error creating Resettlement:", error);
    }
  };
  const handleNextClick = (event) => {
    if (selectedIdFromEntryPass) {
      if (selectedId) {
        // Assume you have another id from ResettlementSite, replace 'resettlementId' with the actual id
        const resettlementId = selectedId; // Replace with your logic to get the id from ResettlementSite

        // Navigate to the beneficiary info page with both ids as state
        const state = {
          entryPassId: selectedIdFromEntryPass,
          resettlementId: resettlementId,
        };
        navigate(`/beneficiary`, { state });
      } else {
        toast.error("Please select a Resettlement Site");
      }
    } else {
      // Handle the case where no data is available
      toast.error("No data available from EntryPass");
      event.preventDefault();
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />

      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>MLQH-RRS</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-2 pr-2">
          <div className="container-fluid">
            <Accordion
              sx={AccordionStyle}
              className="elevation-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="card-title font-weight-bold p-2">
                  <BsIcons.BsPlusLg className="mb-1" /> Information on
                  Resettlement Site
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-2">
                  <hr className="mt-0 pt-0" style={{}} />
                  <small className="text-muted">
                    <BsIcons.BsExclamationCircleFill className="mb-1" /> Check
                    all the required fields before saving
                  </small>

                  <div className="row mt-3">
                    <div className="col-sm-3">
                      <TextField
                        id="outlined-basic"
                        name="resettlementSiteName"
                        value={formData.resettlementSiteName}
                        label="Resettlement Site"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleInputChange}
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                      />
                    </div>
                    {/* <div className="col-sm-3">
                                            <FormControl
                                                className="mb-4"
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-label"
                                                    style={selectLabelStyle}
                                                >
                                                    Regional Office
                                                </InputLabel>
                                                <Select
                                                    id="regionalOffice"
                                                    onChange={onSelectChange}
                                                >
                                                    <MenuItem value="">
                                                        Select Regional Office
                                                    </MenuItem>
                                                    {regionalOffices.map(
                                                        (office) => (
                                                            <MenuItem
                                                                value={
                                                                    office.id
                                                                }
                                                            >
                                                                {
                                                                    office.regionalOffice
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="col-sm-3">
                                            <FormControl
                                                className="mb-4"
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-label"
                                                    style={selectLabelStyle}
                                                >
                                                    District Office
                                                </InputLabel>
                                                <Select
                                                    labelId=""
                                                    id="districtOffice"
                                                    name="districtOffice"
                                                    label="District Office"
                                                    onChange={handleInputChange}
                                                >
                                                    {districtOffices.map(
                                                        (district) => (
                                                            <MenuItem
                                                                key={
                                                                    district.id
                                                                }
                                                                value={
                                                                    district.id
                                                                }
                                                            >
                                                                {
                                                                    district.districtOffice
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div> */}

                    {/* <div className="col-sm-3">
                                            <TextField
                                                id="outlined-basic"
                                                name="projectName"
                                                value={formData.projectName}
                                                label="Project Name"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onChange={handleInputChange}
                                                InputLabelProps={{
                                                    style: labelStyle,
                                                }}
                                                type="text"
                                            />
                                        </div> */}

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Region
                        </InputLabel>
                        <Select
                          label="Region"
                          onChange={province}
                          onSelect={region}
                          value={formData.regionCode}
                        >
                          <MenuItem value=""> Select Region</MenuItem>
                          {regionData &&
                            regionData.length > 0 &&
                            regionData.map((item) => (
                              <MenuItem
                                key={item.region_code}
                                value={item.region_code}
                              >
                                {item.region_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Province
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          label="Province"
                          onChange={city}
                        >
                          {provinceData &&
                            provinceData.length > 0 &&
                            provinceData.map((item) => (
                              <MenuItem
                                key={item.province_code}
                                value={item.province_code}
                              >
                                {item.province_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          City/Municipality
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          // value={City}
                          label="City/Municipality"
                          onChange={barangay}
                        >
                          {cityData &&
                            cityData.length > 0 &&
                            cityData.map((item) => (
                              <MenuItem
                                key={item.city_code}
                                value={item.city_code}
                              >
                                {item.city_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Barangay
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          label="Barangay"
                          onChange={brgy}
                        >
                          {barangayData &&
                            barangayData.length > 0 &&
                            barangayData.map((item) => (
                              <MenuItem
                                key={item.brgy_code}
                                value={item.brgy_code}
                              >
                                {item.brgy_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Chargeability
                        </InputLabel>
                        <Select
                          labelId=""
                          // value={ProgramClass}
                          label="Chargeability"
                          id="chargeability"
                          name="chargeability"
                          onChange={handleInputChange}
                          value={formData.chargeability}
                        >
                          {housingProgramOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {formData.chargeability === "Others" && (
                      <div className="col-sm-3">
                        <TextField
                          id="outlined-basic"
                          name="otherChargeability"
                          value={formData.otherChargeability}
                          label="Others"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="text"
                        />
                      </div>
                    )}
                    {/* <div className="col-sm-3">
                                            <FormControl
                                                className="mb-4"
                                                size="small"
                                                fullWidth
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-label"
                                                    style={selectLabelStyle}
                                                >
                                                    Specific Program
                                                    Classification
                                                </InputLabel>
                                                <Select
                                                    labelId=""
                                                    id="specificClassification"
                                                    name="specificClassification"
                                                    onChange={handleInputChange}
                                                    value={
                                                        formData.specificClassification
                                                    }
                                                    label="Specific Program Classification"
                                                    // onChange={

                                                    // }
                                                >
                                                    {getSpecificClassificationOptions().map(
                                                        (option, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={option}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div> */}

                    <div className="col-sm-12">
                      <Button
                        variant="contained"
                        className="p-2 pl-5 pr-5 float-right text-dark"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#2be0a9",
                          borderRadius: "50px",
                        }}
                      >
                        Submit{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="pl-2 pr-2 mt-4">
            <div
              className="card elevation-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              {/* {currentStep === 1 && (
                            <div className="card-header">
                                <h3 className="card-title font-weight-bold">
                                    List of Place of Origin
                                </h3>
                            </div>
                        )} */}

              {/* {currentStep === 2 && (
                            <div className="card-header">
                                <h3 className="card-title font-weight-bold">
                                    List of Resettlement Site
                                </h3>
                            </div>
                        )}

                        {currentStep === 3 && (
                            <div className="card-header">
                                <h3 className="card-title font-weight-bold">
                                    List of Households
                                </h3>
                            </div>
                        )} */}

              <div
                className="card-body table-responsive p-0"
                style={{ height: 400 }}
              >
                <table className="table table-head-fixed table-hover text-wrap">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <input
                          type="checkbox"
                          style={{ width: "12px" }}
                        ></input>{" "}
                      </th>
                      <th className="text-left">Resettlement Site</th>
                      <th className="text-left">Region</th>
                      <th className="text-left">Province</th>
                      <th className="text-left">City/Municipality</th>
                      <th className="text-left">Barangay</th>
                      <th className="text-left">Chargeability</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id} className="td-hover">
                        <td className="text-center">
                          <input
                            type="checkbox"
                            style={{
                              width: "12px",
                            }}
                            checked={selectedId === item.id}
                            onChange={() => handleRowClick(item.id)}
                          />
                        </td>
                        <td>{item.resettlementSiteName?.toUpperCase()}</td>
                        <td>{item.region?.toUpperCase()}</td>
                        <td>{item.province?.toUpperCase()}</td>
                        <td>{item.city?.toUpperCase()}</td>
                        <td>{item.barangayAddr?.toUpperCase()}</td>
                        <td>{item.chargeability?.toUpperCase()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="card-footer"
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <div className="row cardFooterCenter">
                  {/* <div className="col-sm-6">
                                        <label>1</label>{" "}
                                        <span className="text-muted">of</span>{" "}
                                        <label>3</label> &nbsp;
                                        <IconButton>
                                            <ArrowBackIosIcon className="text-md pl-1" />
                                        </IconButton>
                                        <IconButton>
                                            <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                        </IconButton>
                                    </div> */}
                  <div className="col-sm-6">
                    <div>
                      <Button
                        variant="contained"
                        className="p-2 pl-5 pr-5 float-right text-dark cardFooterItem"
                        onClick={handleNextClick}
                        style={{
                          backgroundColor: "#2be0a9",
                          borderRadius: "50px",
                        }}
                      >
                        next &nbsp; <BsIcons.BsArrowRight />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResettlementSite;
