import { PDFDocument } from "pdf-lib";
import axios from "axios";
import { toast } from "react-toastify";

// Function to merge multiple PDFs
const mergePDFs = async (files) => {
  const mergedPdf = await PDFDocument.create();

  for (const file of files) {
    const arrayBuffer = await file.arrayBuffer();
    const pdfToMerge = await PDFDocument.load(arrayBuffer);

    const copiedPages = await mergedPdf.copyPages(
      pdfToMerge,
      pdfToMerge.getPageIndices()
    );
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  }

  const mergedPdfBytes = await mergedPdf.save();
  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  return blob; // Return the Blob for uploading
};

const UploadPdf = async (selectedFiles, requestNumber) => {
  if (!selectedFiles.length || !requestNumber) {
    toast.error("Please select files and provide a request number.");
    return;
  }

  try {
    const mergedPdfBlob = await mergePDFs(selectedFiles);
    const formData = new FormData();
    formData.append("file", mergedPdfBlob, `${requestNumber}.pdf`);
    formData.append("requestNumber", requestNumber);

    await axios.post("https://api.nha.gov.ph/ep/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });

    toast.success("Merged PDF uploaded successfully!");
  } catch (error) {
    console.error(error);

    if (error.message.includes("Invalid PDF structure")) {
      toast.error("One of the selected files is not a valid PDF.");
    } else if (error.response) {
      // axios-specific error with a response from the server
      toast.error(`Server error: ${error.response.statusText}`);
    } else {
      // Generic error
      toast.error("File upload failed. Please try again later.");
    }
  }
};

export default UploadPdf;
