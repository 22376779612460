import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ accessLevelRequired = null, children }) => {
    const [accessLevel, setAccessLevel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate(); // ✅ Correct way to handle navigation

    useEffect(() => {
        const accessLevelCookie = Cookies.get("accessLevel");

        if (accessLevelCookie) {
            setAccessLevel(Number(accessLevelCookie));
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!isLoading && accessLevel === null) {
            navigate("/login"); // ✅ Move redirect logic inside useEffect
        }
    }, [isLoading, accessLevel, navigate]);

    if (isLoading || accessLevel === null) {
        return null; // ✅ Prevent rendering before redirecting
    }

    // ✅ Check access level
    if (accessLevelRequired === null || accessLevel === accessLevelRequired) {
        return children;
    } else {
        navigate("/access-denied"); // ✅ Redirect unauthorized users
        return null;
    }
};

export default ProtectedRoute;
