import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
// mui icon button and icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";

import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
};

const MHQRRS = () => {
  // REMOVE THIS
  // const regions = {};

  const [currentStep, setCurrentStep] = useState(1);
  // UNTIL HERE ONLY
  // mui
  const selectLabelStyle = {
    fontWeight: "normal",
  };
  const labelStyle = {
    fontWeight: "normal",
  };

  const initialFormData = {
    regionalOffice: "",
    // regionalOfficeID: 0,
    districtOffice: "",
    // districtOfficeID: "",
    projectName: "",
    regionAddr: "",
    regionCode: "",
    provinceAddr: "",
    provinceCode: 0,
    cityAddr: "",
    cityCode: 0,
    barangayAddr: "",
    barangayCode: 0,
    areaClassification: "",
    specificClassification: "",
    specificClassificationOther: "",
    specificArea: "",
  };
  const [regionalOfficeId, setRegionalOfficeId] = useState("");
  const [openModal, setopenModal] = useState(false);
  const modtoggle = () => setopenModal(!openModal);

  const handleclose = () => setopenModal(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const [regionAddr, setRegionAddr] = useState(null);
  const [provinceAddr, setProvinceAddr] = useState(null);
  const [cityAddr, setCityAddr] = useState(null);
  const [barangayAddr, setBarangayAddr] = useState(null);
  const [regionalOffices, setRegionalOffices] = useState([]);
  const [districtOffices, setDistrictOffices] = useState([]);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [submitClicked, setSubmitClicked] = useState(false);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const [filteredData, setFilteredData] = useState([]);
  const [filterProject, setFilterProject] = useState();

  const [accessLevel, setAccessLevel] = useState();
  const [regionalOffice, setRegionalOffice] = useState();
  useEffect(() => {
    const al = Cookies.get("accessLevel");

    if (al && al !== accessLevel) setAccessLevel(al);
  }, [accessLevel]); // Dependency array includes state variables to track changes

  useEffect(() => {
    // Filter data based on filterProject
    if (formData.projectName) {
      const filtered = data.filter(
        (item) =>
          // Convert both projectName and filterProject to lowercase for case-insensitive comparison
          item.projectName &&
          item.projectName
            .toLowerCase()
            .includes(formData.projectName.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      // Reset filtered data if filterProject is empty
      setFilteredData(data); // Reset to original data
    }
  }, [formData.projectName, data]);

  useEffect(() => {
    // Reset currentPage when filterProject changes
    setCurrentPage(1);
  }, [filterProject]);

  const region = () => {
    regions().then((response) => {
      const sortedResponse = response.sort(
        (a, b) => a.region_name.localeCompare(b.region_name) // Adjust the key if needed
      );
      setRegion(sortedResponse);
    });
  };

  const province = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = regionData.find(
      (item) => item.region_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.region_name;
      setFormData((prevState) => ({
        ...prevState,
        regionAddr: selectedText,
        regionCode: selectedValue,
      }));

      provinces(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.province_name.localeCompare(b.province_name) // Adjust the key if needed
          );
          setProvince(sortedResponse);
          setCity([]);
          setBarangay([]);
        })
        .catch((error) => {
          console.error("Error fetching provinces", error);
          // toast.error("Error fetching provinces");
        });
    }
  };

  const city = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = provinceData.find(
      (item) => item.province_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.province_name;
      setFormData((prevState) => ({
        ...prevState,
        provinceAddr: selectedText,
        provinceCode: selectedValue,
      }));

      cities(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.city_name.localeCompare(b.city_name) // Adjust the key if needed
          );
          setCity(sortedResponse);
          setBarangay([]);
        })
        .catch((error) => {
          console.error("Error fetching cities", error);
          // toast.error("Error fetching cities");
        });
    }
  };

  const barangay = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = cityData.find(
      (item) => item.city_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.city_name;
      setFormData((prevState) => ({
        ...prevState,
        cityAddr: selectedText,
        cityCode: selectedValue,
      }));

      barangays(selectedValue)
        .then((response) => {
          const sortedResponse = response.sort(
            (a, b) => a.brgy_name.localeCompare(b.brgy_name) // Adjust the key if needed
          );
          setBarangay(sortedResponse);
        })
        .catch((error) => {
          console.error("Error fetching barangays", error);
          // toast.error("Error fetching barangays");
        });
    }
  };

  const brgy = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = barangayData.find(
      (item) => item.brgy_code === selectedValue
    );

    if (selectedOption) {
      const selectedText = selectedOption.brgy_name;
      setFormData((prevState) => ({
        ...prevState,
        barangayAddr: selectedText,
        barangayCode: selectedValue,
      }));
    }
  };

  useEffect(() => {
    region();
  }, []);

  const resetForm = () => {
    setFormData(initialFormData);
    setRegionalOfficeId("");
    setDistrictOffices([]);
    setProvince([]);
    setCity([]);
    setBarangay([]);
    regions().then((response) => {
      setRegion(response);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let specificClassificationValue = formData.specificClassification;
    if (formData.specificClassification === "Others") {
      specificClassificationValue = `Others: ${formData.specificClassificationOther}`;
    }
    try {
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_pointofOrigin",
        {
          ...formData,
          specificClassification: specificClassificationValue,
          regionalOffice: formData.regionalOffice,
          districtOffice: formData.districtOffice,
        },
        {
          withCredentials: true,
        }
      );

      toast.success("Project successfully created");

      await fetchPointOfOrigin(); // Wait until the data is fetched
      resetForm();
      setFilterProject("");
      setSubmitClicked(true);
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error("UserId is required to create PointOfOrigin");
            break;
          case 409:
            toast.error("A project with these details already exists");
            break;
          default:
            toast.error("Error creating PointOfOrigin");
        }
      } else {
        toast.error("Error creating PointOfOrigin");
      }
      console.error("Error creating PointOfOrigin:", error);
    }
  };

  const fetchPointOfOrigin = async () => {
    try {
      const response = await axios.get(
        "https://api.nha.gov.ph/ep/trx_pointOfOrigin_view",
        { withCredentials: true }
      );

      // Assuming you have a way to access cookies on the frontend

      // Update formData with cookies' values if they are not already set
      setFormData((prevData) => ({
        ...prevData,
        districtOffice: response.data.districtOffice || prevData.districtOffice,
        regionalOffice: response.data.regionalOffice || prevData.regionalOffice,
      }));
      // Filter the data on the frontend

      setData(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const serverMessage =
          error.response.data.details || "Internal Server Error";
        const stackTrace =
          error.response.data.stack || "No stack trace available";
        toast.error(`Error 500: ${serverMessage}`);
        console.error(
          "Server Error:",
          serverMessage,
          "\nStack Trace:",
          stackTrace
        );
      } else {
        toast.error("Error retrieving PointOfOrigin data");
        console.error("Error retrieving data:", error);
      }
    }
  };

  useEffect(() => {
    fetchPointOfOrigin();
  }, [submitClicked]);

  // Utility function to get cookie value by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    fetchPointOfOrigin();
  }, [submitClicked]);

  const handleRowClick = (id) => {
    setSelectedId(id);
  };
  const handleRowCheckboxClick = (clickedItem) => {
    const id = clickedItem.id;
    if (selectedId === id) {
      setSelectedId("");
    } else {
      setSelectedId(id);
    }
  };
  useEffect(() => {
    setRegionalOffices([]);
    axios
      .get("https://api.nha.gov.ph/ep/trx_regional_district_office_fetch", {
        withCredentials: true,
      })
      .then((response) => {
        setRegionalOffices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching offices:", error);
      });
  }, [submitClicked, regionalOffice]);

  const handleRegionalOfficeSelect = async (regionalOfficeId) => {
    try {
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_district_office_fetch_by_regional_office",
        { regionalOfficeId }
      );

      setDistrictOffices(response.data);
    } catch (error) {
      console.error("Error fetching district offices:", error);
    }
  };
  const onSelectChange = (event) => {
    const regionalOfficeIdevent = parseInt(event.target.value); // Parse the value to ensure it's a number
    setRegionalOfficeId(regionalOfficeIdevent);
    // Find the selected regional office based on the selected value (ID)
    const selectedRO = regionalOffices;

    if (selectedRO) {
      setFormData({
        ...formData,
        regionalOffice: selectedRO.regionalOffice,
      });

      handleRegionalOfficeSelect(regionalOfficeIdevent);
    } else {
      console.error("No matching regional office found for the selected ID");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "programClassification") {
      setFormData({
        ...formData, // Spread the current formData values
        programClassification: "", // Update the programClassification field
        specificClassification: "",
        specificClassificationOthers: "",
      });
    }

    if (name === "districtOffice") {
      // if (name === "areaClassification") {
      //     setFormData((prevState) => ({
      //         ...prevState,
      //         specificClassification: "", // or null, depending on your requirements
      //     }));
      // } else
      const selectedDistrict = districtOffices.find(
        (district) => district.id === parseInt(value)
      );
      setFormData({
        ...formData,
        districtOffice: selectedDistrict.districtOffice,
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === "regionCode") {
      const selectedRegion = event.target.selectedOptions[0].text;
      setRegionAddr(selectedRegion);
    } else if (name === "provinceCode") {
      const selectedProvince = event.target.selectedOptions[0].text;
      setProvinceAddr(selectedProvince);
    } else if (name === "cityCode") {
      const selectedCity = event.target.selectedOptions[0].text;
      setCityAddr(selectedCity);
    } else if (name === "barangayCode") {
      const selectedBarangay = event.target.selectedOptions[0].text;
      setBarangayAddr(selectedBarangay);
    }
  };

  const handleNextButtonClick = (event) => {
    // Assuming you have a selectedId state variable to store the selected ID
    if (selectedId) {
      // Navigate to the next page with the selectedId as state
      const state = { id: selectedId };
      navigate(`/resettlementsite`, { state });
    } else {
      // Handle the case where no row is selected
      toast.error("Please select Place of Origin before clicking Next");
      event.preventDefault();
    }
  };

  const getSpecificClassificationOptions = () => {
    switch (formData.areaClassification) {
      case "":
        return ["none"];
      case "Infrastructure Projects":
        return ["DOTr", "DPWH", "NHA"];
      case "Calamity":
        return ["Fire", "Earthquake", "Typhoon", "Volcanic Eruption"];
      case "Old AFP/PNP Housing Project (Beneficiaries of JR No. 2)":
        return ["None"];
      case "Danger Areas":
      case "SC Continuing Mandamus":
        return [
          "Esteros",
          "Railroad Tracks",
          "Garbage Dumps",
          "RiverBanks",
          "Shorelines",
          "Waterways",
          "Public Places",
        ];
      default:
        return [];
    }
  };
  if (!accessLevel) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Header />
      <Sidebar />

      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>
                  MLQH-RRS (
                  {accessLevel == 2
                    ? "District Office"
                    : accessLevel < 2 && accessLevel >= 1
                    ? "Regional Office"
                    : "RDSD"}
                  )
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-2 pr-2">
          <div className="container-fluid">
            <Accordion
              sx={AccordionStyle}
              className="elevation-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="card-title font-weight-bold p-2">
                  <BsIcons.BsPlusLg className="mb-1" /> Information on Place of
                  Origin
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-2">
                  <hr className="mt-0 pt-0" style={{}} />
                  <small className="text-muted">
                    <BsIcons.BsExclamationCircleFill className="mb-1" /> Review
                    all the required fields before saving
                  </small>

                  <div className="row mt-3">
                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Regional Office
                        </InputLabel>
                        <Select
                          id="regionalOffice"
                          name="regionalOffice"
                          label="Regional Office"
                          onChange={onSelectChange}
                          value={formData.regionalOffice}
                          // defaultValue={
                          //     formData.regionalOffice
                          // }
                        >
                          {/* <MenuItem value="">
                                                        Select Regional Office
                                                    </MenuItem> */}

                          <MenuItem value={formData.regionalOffice}>
                            {formData.regionalOffice}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          District Office
                        </InputLabel>
                        <Select
                          labelId=""
                          id="districtOffice"
                          name="districtOffice"
                          label="District Office"
                          onChange={handleInputChange}
                          value={formData.districtOffice}
                        >
                          <MenuItem value={formData.districtOffice}>
                            {formData.districtOffice}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                 

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Region
                        </InputLabel>
                        <Select
                          label="Region"
                          onChange={province}
                          onSelect={region}
                          value={formData.regionCode}
                        >
                          <MenuItem value=""> Select Region</MenuItem>
                          {regionData &&
                            regionData.length > 0 &&
                            regionData.map((item) => (
                              <MenuItem
                                key={item.region_code}
                                value={item.region_code}
                              >
                                {item.region_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Province
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          label="Province"
                          onChange={city}
                        >
                          {provinceData &&
                            provinceData.length > 0 &&
                            provinceData.map((item) => (
                              <MenuItem
                                key={item.province_code}
                                value={item.province_code}
                              >
                                {item.province_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          City/Municipality
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          // value={City}
                          label="City/Municipality"
                          onChange={barangay}
                        >
                          {cityData &&
                            cityData.length > 0 &&
                            cityData.map((item) => (
                              <MenuItem
                                key={item.city_code}
                                value={item.city_code}
                              >
                                {item.city_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Barangay
                        </InputLabel>
                        <Select
                          labelId=""
                          id=""
                          label="Barangay"
                          onChange={brgy}
                        >
                          {barangayData &&
                            barangayData.length > 0 &&
                            barangayData.map((item) => (
                              <MenuItem
                                key={item.brgy_code}
                                value={item.brgy_code}
                              >
                                {item.brgy_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <TextField
                        id="outlined-basic"
                        name="specificArea"
                        label="Specific Area"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleInputChange}
                        value={formData.specificArea}
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Nature of Displacement
                        </InputLabel>
                        <Select
                          labelId=""
                          // value={ProgramClass}
                          label="Nature of Displacement"
                          id="areaClassification"
                          name="areaClassification"
                          onChange={handleInputChange}
                          value={formData.areaClassification}
                        >
                          <MenuItem value="Danger Areas">Danger Areas</MenuItem>
                          <MenuItem value="Infrastructure Projects">
                            Infrastructure Projects
                          </MenuItem>
                          <MenuItem value="SC Continuing Mandamus">
                            SC Continuing Mandamus
                          </MenuItem>
                          <MenuItem value="Old AFP/PNP Housing Project (Beneficiaries of JR No. 2)">
                            Old AFP/PNP Housing Project (Beneficiaries of JR No.
                            2)
                          </MenuItem>
                          <MenuItem value="Calamity">Calamity</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Specific Classification of Displacement
                        </InputLabel>
                        <Select
                          labelId=""
                          id="specificClassification"
                          name="specificClassification"
                          onChange={handleInputChange}
                          value={formData.specificClassification}
                          label="Specific Classification of Displacement"
                          // onChange={

                          // }
                        >
                          {getSpecificClassificationOptions().map(
                            (option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-3">
                    <FormControl className="mb-4" size="small" fullWidth>

                      <TextField
                        id="outlined-basic"
                        name="projectName"
                        value={formData.projectName}
                        label="Project Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleInputChange}
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                      />
                      </FormControl>

                    </div>

                    {formData.specificClassification === "Others" ? (
                      <div className="col-sm-3">
                        <TextField
                          id="specificClassificationOther"
                          name="specificClassificationOther" // Different name for the text field
                          onChange={handleInputChange}
                          value={formData.specificClassificationOther || ""} // Separate state for the text input
                          label="Other Specific Classification of Displacement"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="text"
                        />
                      </div>
                    ) : null}

                    <div className="col-sm-12">
                      <Button
                        variant="contained"
                        className="p-2 pl-5 pr-5 float-right text-dark"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#2be0a9",
                          borderRadius: "50px",
                        }}
                      >
                        Submit{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="pl-2 pr-2 mt-4">
            <div
              className="card elevation-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              {/* {currentStep === 1 && (
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bold">
                                        List of Place of Origin
                                    </h3>
                                </div>
                            )} */}

              {/* {currentStep === 2 && (
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bold">
                                        List of Resettlement Site
                                    </h3>
                                </div>
                            )}

                            {currentStep === 3 && (
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bold">
                                        List of Households
                                    </h3>
                                </div>
                            )} */}

              <div
                className="card-body table-responsive p-0"
                style={{ height: 400 }}
              >
                {currentStep === 1 && (
                  <table className="table table-head-fixed table-hover text-wrap">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Regional Office</th>
                        <th>District Office</th>
                        <th>Region</th>
                        <th>Province</th>
                        <th>City/Municipality</th>
                        <th>Barangay</th>
                        <th>Nature of Displacement</th>
                        <th>Specific Classification</th>
                        <th>Project Name</th>

                      </tr>
                    </thead>

                    <tbody>
                      {filteredData.map((item) => (
                        <tr key={item.id}>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              style={{
                                width: "12px",
                              }}
                              onChange={() => handleRowCheckboxClick(item)}
                              checked={selectedId === item.id}
                            ></input>
                          </td>
                          <td>{item.regionalOffice?.toUpperCase()}</td>
                          <td>{item.districtOffice?.toUpperCase()}</td>
                          <td>{item.regionAddr?.toUpperCase()}</td>
                          <td>{item.provinceAddr?.toUpperCase()}</td>
                          <td>{item.cityAddr?.toUpperCase()}</td>
                          <td>{item.barangayAddr?.toUpperCase()}</td>
                          <td>{item.areaClassification?.toUpperCase()}</td>
                          <td>{item.specificClassification?.toUpperCase()}</td>
                          <td>{item.projectName?.toUpperCase()}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              <div
                className="card-footer"
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <div className="row cardFooterCenter">
                  {/* <div className="col-sm-6">
                                        <label>1</label>{" "}
                                        <span className="text-muted">of</span>{" "}
                                        <label>3</label> &nbsp;
                                        <IconButton>
                                            <ArrowBackIosIcon className="text-md pl-1" />
                                        </IconButton>
                                        <IconButton>
                                            <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                        </IconButton>
                                    </div> */}

                  <div className="col-sm-6">
                    {currentStep === 1 && (
                      <div>
                        <Button
                          variant="contained"
                          className="p-2 pl-5 pr-5 float-right text-dark cardFooterItem"
                          onClick={handleNextButtonClick}
                          style={{
                            backgroundColor: "#2be0a9",
                            borderRadius: "50px",
                          }}
                        >
                          next &nbsp; <BsIcons.BsArrowRight />
                        </Button>
                      </div>
                    )}

                    {/* {currentStep === 2 && (
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    className="w-25 float-right text-dark cardFooterItem"
                                                    style={{
                                                        backgroundColor:
                                                            "#2be0a9",
                                                        borderRadius: "50px",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(
                                                            currentStep + 1
                                                        )
                                                    }
                                                >
                                                    next &nbsp;{" "}
                                                    <BsIcons.BsArrowRight />
                                                </Button>

                                                <Button
                                                    variant="outlined"
                                                    className="w-25 mr-3 float-right text-dark cardFooterItem"
                                                    style={{
                                                        border: "2px solid #2be0a9",
                                                        color: "#24dc8f",
                                                        borderRadius: "50px",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(
                                                            currentStep - 1
                                                        )
                                                    }
                                                >
                                                    <BsIcons.BsArrowLeft />{" "}
                                                    &nbsp; previous
                                                </Button>
                                            </div>
                                        )}

                                        {currentStep === 3 && (
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    className="w-25 float-right text-dark cardFooterItem"
                                                    style={{
                                                        backgroundColor:
                                                            "#2be0a9",
                                                        borderRadius: "50px",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(
                                                            currentStep + 1
                                                        )
                                                    }
                                                >
                                                    next &nbsp;{" "}
                                                    <BsIcons.BsArrowRight />
                                                </Button>

                                                <Button
                                                    variant="outlined"
                                                    className="w-25 mr-3 float-right text-dark cardFooterItem"
                                                    style={{
                                                        border: "2px solid #2be0a9",
                                                        color: "#24dc8f",
                                                        borderRadius: "50px",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(
                                                            currentStep - 1
                                                        )
                                                    }
                                                >
                                                    <BsIcons.BsArrowLeft />{" "}
                                                    &nbsp; previous
                                                </Button>
                                            </div>
                                        )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MHQRRS;
