import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const handleLogout = async (navigate) => {
  try {
    // Make a request to the server's /logout endpoint using axios
    const response = await axios.post(
      "https://api.nha.gov.ph/ep/logout",
      {},
      {
        withCredentials: true, // Include credentials (cookies) in the request
      }
    );

    if (response.status === 200) {
      // Clear user session (remove cookies or perform any other necessary cleanup)
      Cookies.remove("email");
      Cookies.remove("firstName");
      Cookies.remove("accessLevel");
      Cookies.remove("districtOffice");
      Cookies.remove("regionalOffice");
      Cookies.remove("userId");
      Cookies.remove("lastName");

      // Log cookie values after removal

      // Redirect to the login page using react-router-dom's navigate function
      navigate("/");
    } else {
      console.error("Logout failed:", response.statusText);
    }
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export default handleLogout;
