import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";

const EPmonitoring = () => {
  const [startDate, setStartDate] = useState(new Date("2000-01-01"));
  const [endDate, setEndDate] = useState(new Date("9999-01-01"));
  const [regionalOffices, setRegionalOffices] = useState([]);
  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.nha.gov.ph/ep/trx_all_data",
        {
          params: { startDate, endDate },
        }
      );
      const responseData = response.data;
      setRegionalOffices(responseData); // Set regionalOffices directly to responseData
      if (Array.isArray(responseData)) {
        // Extracting EP print statuses dynamically
        const printStatuses = new Set();
        responseData.forEach((entry) => {
          entry.counts.forEach((countEntry) => {
            printStatuses.add(countEntry.epPrintStatus);
          });
        });
      }
    } catch (error) {
      console.error("Error Fetching requests:", error);
    }
  };
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("EP Monitoring Report");

    // Add the first three lines of the title with font size 12
    worksheet.mergeCells("A1:I1"); // Merge cells for the first line of the title
    const titleCell1 = worksheet.getCell("A1");
    titleCell1.value = "National Housing Authority";
    titleCell1.font = { bold: true, size: 12 };
    titleCell1.alignment = { horizontal: "left", vertical: "middle" };

    worksheet.mergeCells("A2:I2"); // Merge cells for the second line of the title
    const titleCell2 = worksheet.getCell("A2");
    titleCell2.value = "Resettlement & Development Services Department";
    titleCell2.font = { bold: true, size: 12 };
    titleCell2.alignment = { horizontal: "left", vertical: "middle" };

    worksheet.mergeCells("A3:I3"); // Merge cells for the third line of the title
    const titleCell3 = worksheet.getCell("A3");
    titleCell3.value = "Relocation & Resettlement Monitoring Division";
    titleCell3.font = { bold: true, size: 12 };
    titleCell3.alignment = { horizontal: "left", vertical: "middle" };

    // Leave a gap for the next title line (row 4 is empty)
    worksheet.addRow([]); // Adds an empty row for the gap

    // Add the last line of the title with font size 16
    worksheet.mergeCells("A5:I5"); // Merge cells for the last line of the title
    const titleCell4 = worksheet.getCell("A5");
    titleCell4.value = "INVENTORY REPORT ON THE STATUS OF ENTRY PASS RELEASED";
    titleCell4.font = { bold: true, size: 16 };
    titleCell4.alignment = { horizontal: "left", vertical: "middle" };

    // Add a blank row after the title
    worksheet.addRow([]); // Adds a blank row after the title

    // Add header rows
    worksheet.addRow([
      "NO. OF EP RELEASED",
      null,
      "LIQUIDATED",
      null,
      null,
      null,
      "SUB-TOTAL",
      "BALANCE",
      "REMARKS",
    ]);
    worksheet.addRow([
      "Regional Office",
      "EP Count",
      "Issued",
      "Cancelled",
      "Deferred",
      "Spoiled",
      "Total Count",
      "Unaccounted Count",
      "Remarks",
    ]);

    // Merge header cells
    worksheet.mergeCells("A7:B7");
    worksheet.mergeCells("C7:G7");
    worksheet.mergeCells("H7:H8");
    worksheet.mergeCells("I7:I8");

    // Style the header row
    worksheet.getRow(7).font = { bold: true, size: 12 };
    worksheet.getRow(8).font = { bold: true, size: 12 };

    // Apply alignment for header cells
    worksheet.getRow(7).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
    worksheet.getRow(8).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Apply borders to the table (header + data rows)
    for (let rowIndex = 7; rowIndex <= worksheet.lastRow.number; rowIndex++) {
      const row = worksheet.getRow(rowIndex);
      row.eachCell((cell, colIndex) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }

    // Add data rows and center them horizontally and vertically
    regionalOffices.forEach((office) => {
      const totalIssued =
        office.counts.find((item) => item.epPrintStatus === "Issued")?.count ||
        0;
      const totalCancelled =
        office.counts.find((item) => item.epPrintStatus === "Cancelled")
          ?.count || 0;
      const totalDeferred =
        office.counts.find((item) => item.epPrintStatus === "Deferred")
          ?.count || 0;
      const totalSpoiled =
        office.counts.find((item) => item.epPrintStatus === "Spoiled")?.count ||
        0;
      const totalCount = office.counts.reduce(
        (acc, curr) => acc + curr.count,
        0
      );
      const unaccountedCount = totalCount - office.epCount;

      const row = worksheet.addRow([
        office.regionalOffice,
        office.epCount,
        totalIssued,
        totalCancelled,
        totalDeferred,
        totalSpoiled,
        totalCount,
        unaccountedCount,
        office.remarks || "-",
      ]);

      // Apply borders to newly added data rows
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        // Apply centering alignment to the cells in the data rows
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });
    });

    // Auto-size columns
    worksheet.columns.forEach((column) => {
      column.width = column.values
        .filter((val) => typeof val === "string")
        .reduce((maxWidth, val) => Math.max(maxWidth, val.length), 10);
    });

    // Generate and download Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "EPMonitoringReport.xlsx");
  };

  return (
    <div>
      <Header />
      <Sidebar />

      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Entry Pass Monitoring Report</h1>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadExcel}
                  sx={{ mt: 2 }}
                >
                  Download Excel
                </Button>
              </div>
            </div>
          </div>
        </section>

        <div className="pl-2 pr-2">
          <div className="container-fluid">
            <div
              className="card elevation-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              <div className="card-header pb-1">
                <p className="mb-0">National Housing Authority</p>
                <p className="mb-0">
                  Resettlement & Development Services Department
                </p>
                <p className="mb-0">
                  Relocation & Resettlement Monitoring Division
                </p>
                <h5 className="font-weight-bold pt-2">
                  INVENTORY REPORT ON THE STATUS OF ENTRY PASS RELEASED
                </h5>
              </div>

              <div
                className="card-body table-responsive p-0"
                style={{ height: "65vh" }}
              >
                <table className="table table-head-fixed table-hover table-bordered text-wrap">
                  <thead
                    style={{
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    <tr>
                      <th
                        colspan="2"
                        className="text-center"
                        style={{
                          verticalAlign: "middle",
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        NO. OF EP RELEASED <br /> (a)
                      </th>
                      <th
                        colspan="5"
                        className="text-center"
                        style={{
                          verticalAlign: "middle",
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        LIQUIDATED <br /> (b)
                      </th>
                      <th
                        rowspan="2"
                        className="text-middle text-center"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        BALANCE <br /> (d-i=j)
                      </th>
                      <th
                        rowspan="2"
                        className="text-middle text-center"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        REMARKS <br /> (k)
                      </th>
                    </tr>

                    <tr className="text-center">
                      <th
                        rowspan="2"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        REGION <br /> (c)
                      </th>
                      <th
                        rowspan="2"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        RELEASED <br /> (d)
                      </th>
                      <th
                        rowspan="5"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        ISSUED <br /> (e)
                      </th>
                      <th
                        rowspan="5"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        CANCELLED <br /> (f)
                      </th>
                      <th
                        rowspan="5"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        DEFERRED <br /> (g)
                      </th>
                      <th
                        rowspan="5"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        SPOILED <br /> (h)
                      </th>
                      <th
                        rowspan="5"
                        style={{
                          border: "1px solid #ccc",
                          backgroundColor: "#f4f4f4",
                        }}
                      >
                        SUB-TOTAL <br /> (i)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {regionalOffices.map((office, index) => (
                      <>
                        <tr key={index + 1}>
                          <td className="text-middle">
                            {office.regionalOffice}
                          </td>
                          <td className="text-middle">{office.epCount}</td>
                          <td className="text-middle">
                            {office.counts.find(
                              (item) => item.epPrintStatus === "Issued"
                            )?.count || 0}
                          </td>
                          <td className="text-middle">
                            {office.counts.find(
                              (item) => item.epPrintStatus === "Cancelled"
                            )?.count || 0}
                          </td>
                          <td className="text-middle">
                            {office.counts.find(
                              (item) => item.epPrintStatus === "Deferred"
                            )?.count || 0}
                          </td>
                          <td className="text-middle text-center">
                            {office.counts.find(
                              (item) => item.epPrintStatus === "Spoiled"
                            )?.count || 0}
                          </td>
                          <td className="text-middle">
                            {office.counts.reduce(
                              (acc, curr) => acc + curr.count,
                              0
                            )}
                          </td>
                          <td className="text-middle">
                            {" "}
                            {office.counts.reduce(
                              (acc, curr) => acc + curr.count,
                              0
                            ) - office.epCount}{" "}
                          </td>
                          <td className="text-middle text-center p-0">
                            {office.remarks}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EPmonitoring;
