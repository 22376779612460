import React from "react";
import { Modal, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Divider } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const EditBeneficiaryModal = ({ open, handleClose, formData, handleInputChange, handleDateChange, handleSubmit, editMode, setEditMode }) => {

  const handleEditMode = (event) => {
    setEditMode(event.target.checked);
  };

  const handleCloseModal = () => {
    setEditMode(false); // Reset edit mode when closing
    handleClose(); // Close the modal
  };

  
    
 
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
  <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
    Edit Beneficiary
  </Typography>
  <Box display="flex" alignItems="center">
  <Checkbox checked={editMode} onChange={handleEditMode} />

    <Typography>Edit</Typography>
  </Box>
</Box>

      

<form onSubmit={async (event) => {
          event.preventDefault();
          const success = await handleSubmit(event);
          if (success) handleCloseModal();
        }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Typography variant="h6">Beneficiary Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <TextField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                disabled={!editMode}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Middle Name"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Sex</InputLabel>
                <Select
                  name="sex"
                  value={formData.sex}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                
                <DesktopDatePicker
                  label="Birthdate"
                disabled={!editMode}
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={(newDate) => handleDateChange("birthDate", newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Civil Status</InputLabel>
                <Select
                  name="civilStatus"
                  value={formData.civilStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widow/er">Widow/er</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Census Tag Number"
                name="tagNumber"
                value={formData.tagNumber}
                onChange={handleInputChange}
                disabled={!editMode}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tenurial Status</InputLabel>
                <Select
                  name="tenurialStatus"
                  value={formData.tenurialStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Structure Owner">Structure Owner</MenuItem>
                  <MenuItem value="Co-Owner">Co-Owner</MenuItem>
                  <MenuItem value="Renter">Renter</MenuItem>
                  <MenuItem value="Caretaker">Caretaker</MenuItem>
                  <MenuItem value="Sharer/Rent-Free Occupant">Sharer/Rent-Free Occupant</MenuItem>
                  <MenuItem value="Mortgagee">Mortgagee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="PQ Number"
                name="pqNumber"
                value={formData.pqNumber}
                onChange={handleInputChange}
                disabled={!editMode}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleInputChange}
                disabled={!editMode}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.hhpwd}
                disabled={!editMode}
                onChange={handleInputChange}
                    name="hhpwd"
                  />
                }
                label="Household PWD"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.solo}
                    onChange={handleInputChange}
                disabled={!editMode}
                    name="solo"
                  />
                }
                label="Solo Parent"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Spouse Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <TextField
                label="Spouse Last Name"
                name="spouseLastName"
                value={formData.spouseLastName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Spouse First Name"
                name="spouseFirstName"
                value={formData.spouseFirstName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Spouse Middle Name"
                name="spouseMiddleName"
                value={formData.spouseMiddleName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Spouse Sex</InputLabel>
                <Select
                  name="spouseSex"
                  value={formData.spouseSex}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">

              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DesktopDatePicker
                  label="Spouse Birthdate"
                  name="spouseAge"
                  value={formData.spouseAge}
                disabled={!editMode}
                onChange={(newDate) => handleDateChange("spouseAge", newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Spouse Civil Status</InputLabel>
                <Select
                  name="spouseCivilStatus"
                  value={formData.spouseCivilStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widow/er">Widow/er</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.spousepwd}
                    onChange={handleInputChange}
                disabled={!editMode}
                    name="spousepwd"
                  />
                }
                label="Spouse PWD"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Second Spouse Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <TextField
                label="Second Spouse Last Name"
                name="secondSpouseLastName"
                value={formData.secondSpouseLastName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Second Spouse First Name"
                name="secondSpouseFirstName"
                value={formData.secondSpouseFirstName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Second Spouse Middle Name"
                name="secondSpouseMiddleName"
                value={formData.secondSpouseMiddleName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Second Spouse Sex</InputLabel>
                <Select
                  name="secondSpouseSex"
                  value={formData.secondSpouseSex}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Second Spouse Birthdate"
                  name="secondSpouseAge"
                  value={formData.secondSpouseAge}
                  onChange={(newDate) => handleDateChange("secondSpouseAge", newDate)}
                disabled={!editMode}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Second Spouse Civil Status</InputLabel>
                <Select
                  name="secondSpouseCivilStatus"
                  value={formData.secondSpouseCivilStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widow/er">Widow/er</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Previous Spouse Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <TextField
                label="Previous Spouse Last Name"
                name="previousSpouseLastName"
                value={formData.previousSpouseLastName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Previous Spouse First Name"
                name="previousSpouseFirstName"
                value={formData.previousSpouseFirstName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Previous Spouse Middle Name"
                name="previousSpouseMiddleName"
                value={formData.previousSpouseMiddleName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Previous Spouse Sex</InputLabel>
                <Select
                  name="previousSpouseSex"
                  value={formData.previousSpouseSex}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Previous Spouse Birthdate"
                  name="previousSpouseAge"
                  value={formData.previousSpouseAge}
                  onChange={(newDate) => handleDateChange("previousSpouseAge", newDate)}
                disabled={!editMode}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Previous Spouse Civil Status</InputLabel>
                <Select
                  name="previousSpouseCivilStatus"
                  value={formData.previousSpouseCivilStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widow/er">Widow/er</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Senior Co-Owner Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <TextField
                label="Senior Last Name"
                name="seniorLastName"
                value={formData.seniorLastName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Senior First Name"
                name="seniorFirstName"
                value={formData.seniorFirstName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Senior Middle Name"
                name="seniorMiddleName"
                value={formData.seniorMiddleName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Senior Sex</InputLabel>
                <Select
                  name="seniorSex"
                  value={formData.seniorSex}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Senior Birthdate"
                  name="seniorAge"
                  value={formData.seniorAge}
                  onChange={(newDate) => handleDateChange("seniorAge", newDate)}
                disabled={!editMode}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Senior Civil Status</InputLabel>
                <Select
                  name="seniorCivilStatus"
                  value={formData.seniorCivilStatus}
                disabled={!editMode}
                onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widow/er">Widow/er</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.seniorpwd}
                    onChange={handleInputChange}
                disabled={!editMode}
                    name="seniorpwd"
                  />
                }
                label="Senior PWD"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Relocation Information</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid> 
            <Grid item xs={12} sm={6}>
              <TextField
                label="Batch Number"
                name="batchNumber"
                value={formData.batchNumber}
                onChange={handleInputChange}
                fullWidth
                disabled={!editMode}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Relocation Date"
                  name="relocationDate"
                  value={formData.relocationDate}
                  onChange={(newDate) => handleDateChange("relocationDate", newDate)}
                disabled={!editMode}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default EditBeneficiaryModal;