import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import * as BsIcons from "react-icons/bs";

// mui
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { BsUpload } from "react-icons/bs";

import Beneficiary from "./Beneficiary";
import UploadPdf from "./UploadPdf";

import dayjs from "dayjs";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { padding } from "@mui/system";
import "../App.css";
import OtpButton from "./Otp";
const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
};
const RequestList = () => {
  const selectLabelStyle = {
    fontWeight: "normal",
  };
  const labelStyle = {
    fontWeight: "normal",
  };
  const accessLevel = Cookies.get("accessLevel");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [targetCount, setTargetCount] = useState();
  const requestNumberFromDO = useParams();
  const location = useLocation();
  const [fileSelected, setFileSelected] = useState(false);
  const { state } = location;
  let batchNumber = state?.batchNumber || null;
  let requestRequest = state?.requestNumber || null;
  const rdsdIn = state?.rdsdIn || null;
  const regionalIn = state?.regionalIn || null;
  const [ids, setIds] = useState();
  const [selectedIdsAsInt, setSelectedIdsAsInt] = useState([]);
  const [step, setStep] = useState();
  const [familiesCount, setFamiliesCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000; // Adjust the number of items per page as needed

  const handleOtpVerification = (verified) => {
    setIsOtpVerified(verified);
  };
  const [formData, setFormData] = useState({
    data: [],
    areaClassification: "",
    chargeability: "",
    targetFamilies: "",
    projectName: "",
    resettlementSiteName: "",
    requestNumber: "",
    purpose: "",
    typeOfRelocation: "",
    targetRelocation: "",
    numberOfIssuedEP: "",
    remainingBalance: "",
    remarks: "",
    beneficiaryId: [],
    status: "",
  });
  // useEffect(() => {
  //     if (requestNumberFromDO) {
  //         setFormData((prevState) => ({
  //             ...prevState,
  //             requestNumber: "2024-" + requestNumberFromDO,
  //         }));
  //     }
  // }, [requestNumberFromDO]);

  const handleFileSelected = (e) => {
    const files = e.target.files; // Get all selected files
    setSelectedFiles(files); // Store files in state
  };

  const handleUpload = async (file) => {
    try {
      // Call the upload function
      const response = await UploadPdf(file, formData.requestNumber);
      // Assuming UploadPdf resolves if upload succeeds
      return "Upload successful";
    } catch (error) {
      // Handle any errors from UploadPdf
      console.error("Upload failed:", error);
      throw new Error("Upload failed"); // Throw the error for the caller to handle
    }
  };

  const handleDataFromChild = (data) => {
    // Assuming that 'data' is an array of beneficiaryIds
    if (accessLevel == 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        beneficiaryId: data.selectedIds,
      }));
    }
    // Set familiesCount separately
    if (data.selectedIds) {
      setFamiliesCount(data.selectedIds.length);
    }
  };

  const navigate = useNavigate();

  const [openModal, setopenModal] = useState(false);
  const modtoggle = () => setopenModal(!openModal);
  const handleclose = () => setopenModal(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [selectedIds, setSelectedIds] = useState(state.selectedIds || []);
  const [currentStep, setCurrentStep] = useState(1);

  const [districtOffice, setDistrictOffice] = useState("");
  const [regionalOffice, setRegionalOffice] = useState("");
  const [evaluator, setEvaluator] = useState("");
  const userId = Cookies.get("userId");
  useEffect(() => {
    const firstName = Cookies.get("firstName") || "";
    const lastName = Cookies.get("lastName") || "";
    const fullName = `${firstName} ${lastName}`;
    setEvaluator(fullName);
  }, []);
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [filteredBeneficiaryIds, setFilteredBeneficiaryIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  function calculateAge(birthDate) {
    const today = dayjs();
    const birthDateObj = dayjs(birthDate); // Use dayjs to parse the birth date
    let age = today.year() - birthDateObj.year();
    const monthDiff = today.month() - birthDateObj.month();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.date() < birthDateObj.date())
    ) {
      age--;
    }

    return age;
  }

  const handleRowCheckboxClick = (item) => {
    if (selectedIds.includes(item.id)) {
      setSelectedIds(selectedIds.filter((id) => id !== item.id));
    } else {
      // If the item is not selected, add it to the selectedIds
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.nha.gov.ph/ep/trx_beneficiary_spouse_data",
        {
          withCredentials: true, // Correctly placed inside the options object
        }
      );

      if (response.status === 200) {
        const allBeneficiaries = response.data;
        let beneficiariesToSet = allBeneficiaries;

        // Check if requestNumber exists in the data
        if (requestRequest) {
          beneficiariesToSet = allBeneficiaries.filter(
            (beneficiary) => beneficiary.requestNumber == requestRequest
          );
          const filteredIds = beneficiariesToSet.map(
            (beneficiary) => beneficiary.id
          );
          setFilteredBeneficiaryIds(filteredIds);
        }

        // Handle accessLevel == 2 and no requestNumber
        if (accessLevel == 2 && !requestRequest) {
          beneficiariesToSet = allBeneficiaries.filter((beneficiary) =>
            selectedIds.includes(beneficiary.id)
          );

          const filteredIds = beneficiariesToSet.map(
            (beneficiary) => beneficiary.id
          );
          setFilteredBeneficiaryIds(filteredIds);
        }

        // Set the filtered data and beneficiaries
        setData(beneficiariesToSet);
        setFilteredBeneficiaries(beneficiariesToSet);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data. Please try again.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      // 1. Validate files for access levels 1 and 2
      if ((accessLevel == 1 || accessLevel == 2) && !selectedFiles?.length) {
        toast.error("Please upload requirements");
        return;
      }

      // 2. Check WPB Target
      if (
        Number(accessLevel) > 0.2 &&
        Number(accessLevel) < 2 &&
        Number(formData.remainingBalance) < Number(formData.targetFamilies)
      ) {
        toast.error("This request will exceed your WPB Target");
        return;
      }

      // 3. Check for existing request (only for access level 2)
      if (accessLevel == 2) {
        try {
          const existingRequestResponse = await axios.post(
            `https://api.nha.gov.ph/ep/trx_fetch_single_requests/${formData.requestNumber}`,
            { accessLevel },
            { withCredentials: true }
          );

       
        } catch (error) {
          if (!error.response || error.response.status !== 404) {
            throw error;
          }
          // 404 means request number doesn't exist, which is what we want
        }
      }

      // 4. Handle file upload if needed
      if ((accessLevel == 1 || accessLevel == 2 || accessLevel == 0) && selectedFiles?.length > 0) {
        try {
          await handleUpload(selectedFiles);
        } catch (error) {
          toast.error("File upload failed: " + error.message);
          return;
        }
      }

      let requestDataToSend = "";
      const date = new Date();

      // Format the date to mm-dd-yyyy
      const formattedDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Manila", // Use Philippines timezone
      });

      // Format the time to normal-looking time with +8 timezone
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "Asia/Singapore", // +8 timezone
      });

      // Combine formatted date and time
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      if (
        accessLevel == 1 &&
        formData.status == "Evaluated by Regional Office"
      ) {
        if (familiesCount.length == 0) {
          toast.error("Please select Beneficiary");
          return;
        }
        requestDataToSend = {
          ...formData,
          requestNumber: requestRequest,
          targetRelocation: formData.targetRelocation,
          numberOfIssuedEP: formData.numberOfIssuedEP,
          remainingBalance: formData.remainingBalance,
          status: formData.status,
          beneficiaryId: selectedIds,
          step: 4,
          userId: userId,
          accessLevel: accessLevel,
          districtEvaluator: evaluator,
          regionalIn: regionalIn,
          regionalOffice: regionalOffice,
        };
      } else if (
        accessLevel == 1 &&
        formData.status === "With Findings"
      ) {
        requestDataToSend = {
          status: formData.status,
          ...formData,
          step: 2,
        };
      } else if (
        accessLevel == 1.1 &&
        formData.status === "Recommended for Approval (Regional Office)"
      ) {
        requestDataToSend = {
          status: formData.status,
          ...formData,
          step: 4.1,
        };
      } else if (
        accessLevel == 1.1 &&
        formData.status === "With Findings (Regional Office)"
      ) {
        requestDataToSend = {
          status: formData.status,
          ...formData,
          step: 3,
        };
      } else if (
        accessLevel == 1.2 &&
        formData.status === "Approved by Regional Office"
      ) {
        requestDataToSend = {
          ...formData,
          status: formData.status,
          step: 4.2,
          regionalOut: formattedDateTime,
        };
      } else if (
        accessLevel == 1.2 &&
        formData.status === "Dissapproved by Regional Office"
      ) {
        requestDataToSend = {
          ...formData,
          status: formData.status,
          step: 3,
          regionalOut: formattedDateTime,
        };
      } else if (accessLevel == 0 && formData.status === "Evaluated by RDSD") {
        requestDataToSend = {
          ...formData,
          accessLevel: accessLevel,
          requestNumber: formData.requestNumber,
          status: formData.status,
          // targetFamilies: familiesCount,
          userId: userId,
          regionalEvaluator: evaluator,
          rdsdOut: formattedDateTime,
          step: 5,
        };
      } else if (accessLevel == 0 && formData.status === "With Findings") {
        requestDataToSend = {
          ...formData,
          status: formData.status,
          step: 3,
        };
      } else if (
        accessLevel == 0.1 &&
        formData.status === "Recommended for Approval (RDSD)"
      ) {
        requestDataToSend = {
          ...formData,
          status: formData.status,
          rdsdRAOut: formattedDateTime,
          step: 5.1,
        };
      } else if (
        accessLevel == 0.1 &&
        formData.status === "With Findings (RDSD)"
      ) {
        requestDataToSend = {
          ...formData,
          status: formData.status,
          step: 3,
        };
      } else if (accessLevel == 0.2 && formData.status === "Approved by RDSD") {
        requestDataToSend = {
          ...formData,
          step: 5.2,
          status: formData.status,
          rdsdDMOut: formattedDateTime,
          region: regionalOffice,
        };
      } else if (
        accessLevel == 0.2 &&
        formData.status === "Disapproved by RDSD"
      ) {
        requestDataToSend = {
          ...formData,
          step: 3,
          status: formData.status,
          rdsdOut: formattedDateTime,
        };
      } else if (accessLevel == 2) {
        requestDataToSend = {
          ...formData,
          areaClassification: formData.areaClassification,
          chargeability: formData.chargeability,
          targetFamilies: formData.targetFamilies,
          projectName: formData.projectName,
          resettlementSiteName: formData.resettlementSiteName,
          requestNumber: formData.requestNumber,
          purpose: formData.purpose,
          typeOfRelocation: formData.typeOfRelocation,
          beneficiaryId: selectedIds,
          userId: userId,
          status: "Waiting for Regional Office's Evaluation",
          regionalOffice: regionalOffice,
          districtOffice: districtOffice,
          step: 3,
          districtOut: formattedDateTime, // Assign current time to districtOut
        };
        setCurrentStep(1);
      } else {
        toast.error("DATA NOT SUBMITTED");
        return;
      }

      if (selectedIds || (accessLevel == 1 && selectedIdsAsInt.length === 0)) {
        const mappedIds = selectedIds.map((id) => parseInt(id));
        setSelectedIdsAsInt(mappedIds);
      }

      // Make sure it's a valid integer

      // Prepare the form data to be sent to the backend

      const url = "https://api.nha.gov.ph/ep/submitRequest";
      const requestData = {
        formData: requestDataToSend,
        userId: userId,
        accessLevel: accessLevel,
      };

      if (batchNumber) {
        requestData.selectedIds = selectedIds;
      } else if (requestNumberFromDO) {
        requestData.selectedIds = selectedIds;
      }

      if (accessLevel < 2 && requestDataToSend.beneficiaryId.length === 0) {
        toast.error("Please select beneficiary");
        return;
      }

      const response = await axios.post(url, requestData, {
        withCredentials: true,
      });

      if (response.status === 200) {
        toast.success("Form data submitted successfully");
        setFormData({
          targetRelocation: null,
          numberOfIssuedEP: null,
          remainingBalance: null,
          status: "",
        });
        navigate(`/requesttable`);
      } else {
        toast.error("Failed to submit form data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error(
        "Error submitting form: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleSelectAllCheckbox = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If select all is checked, set all IDs to selected
      const allIds = data.map((item) => item.id); // Use the entire dataset instead of currentItems
      setSelectedIds(allIds);
    } else {
      // If select all is unchecked, clear all selected IDs
      setSelectedIds([]);
    }
  };
  useEffect(() => {
    const fetchBeneficiaryData = async () => {
      if (accessLevel == 2) {
        setLoading(true); // Step 2: Set loading to true before starting data fetch

        try {
          const requestBody = {};

          if (batchNumber) {
            requestBody.batchNumber = batchNumber;
            requestBody.selectedIds = selectedIds || [];
            requestBody.accessLevel = Cookies.get("accessLevel");
          } else if (requestRequest) {
            requestBody.accessLevel = Cookies.get("accessLevel");
            requestBody.requestNumber = requestRequest;

            requestBody.selectedIds = selectedIds || [];
          }

          const response = await axios.post(
            `https://api.nha.gov.ph/ep/trx_beneficiary_batch_data`,
            requestBody
          );

          if (response.status !== 200) {
            console.error(
              "Error fetching beneficiary data:",
              response.status,
              response.statusText,
              response.data
            );

            // Handle the error here, you may want to display a message to the user or take appropriate action
            return;
          }

          const requestData = response.data.beneficiaries;
          const requestSecondDate = response.data.request;
          const stepData = requestData[0].step;

          setStep(stepData);

          const updatedFormData = {
            ...formData,
            data: requestData,
            // areaClassification: "",
            // projectName: "",
            // resettlementSiteName: "",
            // chargeability: "",
            targetFamilies: response.data.targetFamiliesCount, // Update targetFamilies directly from response
            // requestNumber: "",
            // typeOfRelocation: "",
            // purpose: "",
          };

          requestData.forEach((requestDataItem) => {
            const pointOfOrigin = requestDataItem.PointOfOrigin;
            if (pointOfOrigin) {
              updatedFormData.projectName = pointOfOrigin.projectName;
              updatedFormData.areaClassification =
                pointOfOrigin.areaClassification;
            }

            const resettlement = requestDataItem.Resettlement;
            if (resettlement) {
              updatedFormData.resettlementSiteName =
                resettlement.resettlementSiteName;
              updatedFormData.chargeability = resettlement.chargeability;
            }

            const spouseData = requestDataItem.Spouse;

            const secondSpouseData = requestDataItem.SecondSpouse;
          });

          setFormData(updatedFormData);
        } catch (error) {
          console.error("Error fetching beneficiary data:", error.message);
          // Handle the error here, you may want to display a message to the user or take appropriate action
        } finally {
          setLoading(false); // Step 2: Set loading to false after data fetch completes
        }
      }
    };

    fetchBeneficiaryData();
  }, []);

  const calculateRemainingBalance = () => {
    const targetRelocation = parseInt(formData.targetRelocation);
    const numberOfIssuedEP = parseInt(formData.numberOfIssuedEP);
    if (isNaN(targetRelocation) || isNaN(numberOfIssuedEP)) {
      setFormData({
        ...formData,
        remainingBalance: 0,
      });
    }
    if (!isNaN(targetRelocation) && !isNaN(numberOfIssuedEP)) {
      const remainingBalance = targetRelocation - numberOfIssuedEP;
      setFormData({
        ...formData,
        remainingBalance: remainingBalance.toString(),
      });
    }
  };

  useEffect(() => {
    const checkAccessAndCalculate = () => {
      if (accessLevel == 1) {
        calculateRemainingBalance();
      }
    };

    checkAccessAndCalculate();
  }, [formData.targetRelocation, formData.numberOfIssuedEP]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const statusOptions =
    accessLevel == 0
      ? [
          { value: "", label: "Select Status" },
          { value: "Evaluated by RDSD", label: "Evaluated by RDSD" },
          { value: "With Findings", label: "With Findings" },
        ]
      : accessLevel == 0.1
      ? [
          { value: "", label: "Select Status" },
          {
            value: "Recommended for Approval (RDSD)",
            label: "Recommended for Approval (RDSD)",
          },
          {
            value: "With Findings (RDSD)",
            label: "With Findings (RDSD)",
          },
        ]
      : accessLevel == 0.2
      ? [
          { value: "", label: "Select Status" },
          { value: "Approved by RDSD", label: "Approved by RDSD" },
          {
            value: "Disapproved by RDSD",
            label: "Disapproved by RDSD",
          },
        ]
      : accessLevel == 1.1
      ? [
          { value: "", label: "Select Status" },
          {
            value: "Recommended for Approval (Regional Office)",
            label: "Recommended for Approval (Regional Office)",
          },
          {
            value: "With Findings (Regional Office)",
            label: "With Findings (Regional Office)",
          },
        ]
      : accessLevel == 1.2
      ? [
          { value: "", label: "Select Status" },
          {
            value: "Approved by Regional Office",
            label: "Approved by Regional Office",
          },
          {
            value: "Disapproved by Regional Office",
            label: "Disapproved by Regional Office",
          },
        ]
      : accessLevel == 1
      ? [
          { value: "", label: "Select Status" },
          {
            value: "Evaluated by Regional Office",
            label: "Evaluated by Regional Office",
          },
          { value: "With Findings", label: "With Findings" },
        ]
      : [];

  useEffect(() => {
    const fetchRequests = () => {
      if (accessLevel < 2 && requestNumberFromDO !== null) {
        setLoading(true);
        const requestNumber = requestRequest;

        axios
          .post(
            `https://api.nha.gov.ph/ep/trx_fetch_single_requests/${requestNumber}`,
            { userId: userId, accessLevel: accessLevel }, // This is the request body
            { withCredentials: true } // This is the config object
          )
          .then((response) => {
            if (response.status === 200) {
              const firstRequest = response.data.request;

              setFormData((prevFormData) => ({
                ...prevFormData,
                requestNumber: firstRequest.requestNumber,
                purpose: firstRequest.purpose,
                typeOfRelocation: firstRequest.typeOfRelocation,
                remarks: firstRequest.remarks,
                data: firstRequest.requestData,
                areaClassification: firstRequest.areaClassification,
                projectName: firstRequest.projectName,
                resettlementSiteName: firstRequest.resettlementSiteName,
                chargeability: firstRequest.chargeability,
                targetFamilies: firstRequest.targetFamilies,
                targetRelocation: firstRequest.targetRelocation,
                numberOfIssuedEP: firstRequest.numberOfIssuedEP,
                remainingBalance: firstRequest.remainingBalance,
                beneficiaryId: firstRequest.beneficiaryId,
              }));
              setRegionalOffice(firstRequest.regionalOffice);
              setDistrictOffice(firstRequest.districtOffice);
            }
          })
          .catch((error) => {
            console.error("Error fetching requests:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    fetchRequests();
  }, [requestNumberFromDO, accessLevel, requestRequest, userId]);

  // All dependencies should remain in the array
  if (loading) {
    return <div>Loading...</div>; // Step 3: Show loading indicator while fetching data
  }
  return (
    <>
      <div>
        <Header />
        <Sidebar />
        <div className="container-fluid">
          <div
            className="content-wrapper pl-2 pr-2 pb-3"
            style={{ height: "50vh" }}
          >
            <section className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6 p-0">
                    <h1>
                      REQUEST (
                      {accessLevel == 2
                        ? "District Office"
                        : accessLevel < 2 && accessLevel >= 1
                        ? "Regional Office"
                        : "RDSD"}
                      )
                    </h1>
                  </div>
                </div>
              </div>
            </section>
            <Accordion
              sx={AccordionStyle}
              className="elevation-2 m-2"
              style={{
                borderTop: "4px solid #292726",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="card-title font-weight-bold p-2">
                  <BsIcons.BsPlusLg className="mb-1" /> Information on Request
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-2">
                  <hr className="mt-0 pt-0" style={{}} />
                  <small className="text-muted">
                    <BsIcons.BsExclamationCircleFill className="mb-1" /> Review
                    all the required fields before saving
                  </small>

                  <div className="row mt-3 d-flex justify-content-between">
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="programClassification"
                        // defaultValue={
                        //     formData.areaClassification
                        // }
                        value={formData.areaClassification}
                        label="Nature of Displacement"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="chargeability"
                        // defaultValue={
                        //     formData.chargeability
                        // }
                        value={formData.chargeability}
                        label="Chargeability of Units"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="targetFamilies"
                        // defaultValue={
                        //     formData.targetFamilies
                        // }
                        value={formData.targetFamilies}
                        label="Number of Families for Relocation"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="pointOfOrigin"
                        // defaultValue={formData.projectName}
                        value={formData.projectName}
                        label="Place of Origin: Project Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="resettlementSiteName"
                        // defaultValue={
                        //     formData.resettlementSiteName
                        // }
                        value={formData.resettlementSiteName}
                        label="Proposed Resettlement Site"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="requestNumber"
                        value={formData.requestNumber}
                        label="Request Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled={accessLevel < 2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <TextField
                        id="outlined-basic"
                        name="purpose"
                        // defaultValue={formData.purpose}
                        value={formData.purpose}
                        label="Relocation and Resettlement Schedule"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: labelStyle,
                        }}
                        type="text"
                        disabled={accessLevel < 2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <FormControl className="mb-4" size="small" fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={selectLabelStyle}
                        >
                          Type Of Relocation and Resettlement
                        </InputLabel>
                        <Select
                          labelId=""
                          // value={ProgramClass}
                          label="Type Of Relocation and Resettlement"
                          id="typeOfRelocation"
                          name="typeOfRelocation"
                          onChange={handleInputChange}
                          disabled={accessLevel < 2}
                          value={formData.typeOfRelocation}
                        >
                          <MenuItem value="Off-City (Actual RR)">
                            Off-City (Actual RR)
                          </MenuItem>
                          <MenuItem value="In-City (Actual RR)">
                            In-City (Actual RR)
                          </MenuItem>
                          <MenuItem value="EP Post-Issuance">
                            EP Post-Issuance
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {accessLevel < 2 ? (
                    <div className="row">
                      <div className="col">
                        <TextField
                          id="outlined-basic"
                          name="targetRelocation"
                          label="Target Number of Families for Relocation in Current Year"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={formData.targetRelocation}
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="number"
                          disabled={accessLevel < 1 || accessLevel > 1.3}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          id="outlined-basic"
                          name="numberOfIssuedEP"
                          label="Number of Issued Entry Pass in Current
                                        Year"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={formData.numberOfIssuedEP}
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="number"
                          disabled={accessLevel < 1 || accessLevel > 1.3}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          id="outlined-basic"
                          name="remainingBalance"
                          label="Relocation Target/Balance in Current Year"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={formData.remainingBalance}
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="number"
                          disabled={accessLevel < 1 || accessLevel > 1.3}
                        />
                      </div>
                    </div>
                  ) : null}
                  {accessLevel !== "2" && (
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <FormControl className="mb-4" size="small" fullWidth>
                          <InputLabel
                            id="status-label"
                            style={selectLabelStyle}
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="status-label"
                            id="status"
                            name="status"
                            onChange={handleInputChange}
                            value={formData.status}
                            label="Status"
                          >
                            {statusOptions.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-sm-6">
                        <TextField
                          id="outlined-basic"
                          name="remarks"
                          label="Evaluation"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={formData.remarks}
                          InputLabelProps={{
                            style: labelStyle,
                          }}
                          type="text"
                          // disabled={
                          //     accessLevel < 1 ||
                          //     accessLevel > 1.3
                          // }
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-sm-12">
                      {accessLevel == 1.2 && (
                        <button className="btn btn-primary">
                          <OtpButton onOtpVerified={handleOtpVerification} />
                        </button>
                      )}

                      {accessLevel == 1 || accessLevel == 2 ? (
                        <div className="upload-pdf">
                          <input
                            type="file"
                            id="file-upload"
                            onChange={handleFileSelected}
                            multiple
                            accept=".pdf"
                            style={{
                              display: "none",
                            }} // Hide the actual input
                          />
                          <label
                            htmlFor="file-upload"
                            className="custom-file-upload"
                          >
                            <BsUpload /> Select PDF Files
                          </label>
                          <div className="file-list">
                            {Array.from(selectedFiles).map((file, index) => (
                              <div key={index}>{file.name}</div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="upload-pdf"></div>
                      )}
                      <Button
                        variant="contained"
                        className="p-2 pl-5 pr-5 float-right text-dark"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#2be0a9",
                          borderRadius: "50px",
                        }}
                        disabled={accessLevel == 1.2 && !isOtpVerified} // Disable if accessLevel is 1.2 and OTP is not verified
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {accessLevel > 0.5 ? (
              <div className="mt-5 ml-2 mr-2">
                <h4 className="font-weight-bold">Beneficiary Information</h4>

                <div
                  className="card elevation-2"
                  style={{
                    borderTop: "4px solid #292726",
                    borderRadius: "15px",
                  }}
                >
                  <div className="card-header">
                    <h3 className="card-title font-weight-bold">
                      List of Household
                    </h3>
                  </div>

                  <div
                    className="card-body table-responsive p-0"
                    style={{ height: 500 }}
                  >
                    {currentStep === 1 && Number(accessLevel) > 0.5 ? (
                      <table className="table table-head-fixed table-hover table-bordered text-wrap">
                        <thead
                          style={{
                            borderBottom: "2px solid #ccc",
                          }}
                        >
                          <tr>
                            {accessLevel > 2 || accessLevel <= 1 ? (
                              <th
                                rowspan="2"
                                colspan="1"
                                style={{
                                  border: "1px solid #ccc",
                                  verticalAlign: "middle",
                                  backgroundColor: "#f4f4f4",
                                }}
                              >
                                {accessLevel < 2 && accessLevel > 0.5 ? (
                                  <input
                                    type="checkbox"
                                    onChange={handleSelectAllCheckbox}
                                  />
                                ) : null}
                              </th>
                            ) : null}
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                                verticalAlign: "middle",
                              }}
                            >
                              #
                            </th>
                            <th
                              colspan="5"
                              className="text-center"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              NAME OF HOUSEHOLD
                            </th>
                            <th
                              rowspan="2"
                              className="text-center"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Age
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Civil Status
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Census Tag Number
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Tenurial
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              PQ Number
                            </th>
                            <th
                              rowspan="2"
                              className="text-center"
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Action
                            </th>
                          </tr>

                          <tr>
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Last Name
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              First Name
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Middle Name
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Suffix
                            </th>
                            <th
                              rowspan="2"
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              Sex
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredBeneficiaries.map((item, index) => {
                            const age = calculateAge(item.birthDate); // Calculate age for each record
                            const continuousIndex =
                              (currentPage - 1) * itemsPerPage + index + 1;

                            return (
                              <tr key={item.id}>
                                {accessLevel == 1 ? (
                                  <td className="text-center borderRight">
                                    <input
                                      type="checkbox"
                                      style={{
                                        width: "12px",
                                      }}
                                      checked={selectedIds.includes(item.id)}
                                      onChange={() =>
                                        handleRowCheckboxClick(item)
                                      }
                                    />
                                  </td>
                                ) : null}
                                <td className="text-middle">
                                  {continuousIndex}
                                </td>
                                <td className="text-middle">{item.lastName}</td>
                                <td className="text-middle">
                                  {item.firstName}
                                </td>
                                <td className="text-middle">
                                  {item.middleName}
                                </td>
                                <td className="text-middle">{item.suffix}</td>
                                <td className="text-middle">{item.sex}</td>
                                <td className="text-middle text-center">
                                  {age}
                                </td>
                                <td className="text-middle">
                                  {item.civilStatus}
                                </td>
                                <td className="text-middle">
                                  {item.tagNumber}
                                </td>
                                <td className="text-middle">
                                  {item.tenurialStatus}
                                </td>
                                <td className="text-middle">{item.pqNumber}</td>
                                <td className="text-middle text-center p-0">
                                  <Tooltip title="View Info">
                                    <IconButton>
                                      <a
                                        href={`/#/beneficiary-info/${item.id}`}
                                      >
                                        <RemoveRedEyeRoundedIcon
                                          className="text-lg"
                                          style={{
                                            color: "#34a8ff",
                                          }}
                                        />
                                      </a>
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* <Beneficiary
                        isInRequestComponent={true}
                        accessLevel={accessLevel}
                        requestNumberFromRequests={requestRequest}
                        selectedIds2={selectedIds}
                        onDataFromChild={handleDataFromChild}
                    /> */}
        </div>
      </div>
    </>
  );
};

export default RequestList;
