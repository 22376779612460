import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

export function StatusAward({
  startDate,
  endDate,
  selectedRegionalOffice,
  selectedStatus,
}) {
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.nha.gov.ph/ep/trx_all_data_civil_status",
          {
            params: {
              startDate,
              endDate,
              selectedRegionalOffice,
              selectedStatus,
            },
          }
        );
        const responseData = response.data;
        if (responseData) {
          const pieData = {
            datasets: responseData.datasets,
            labels: responseData.labels,
          };
          setPieData(pieData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, selectedRegionalOffice, selectedStatus]);

  if (!pieData.labels.length) {
    return <p>No data available for the selected filters.</p>;
  }

  return <Pie data={pieData} />;
}
