import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// mui
import IconButton from "@mui/material/IconButton";
// mui icon
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { BsPencilSquare } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

const BatchDetails = () => {
  const { batchNumber } = useParams();
  const [batch, setBatch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedIds, setSelectedIds] = useState([]);
  const navigate = useNavigate();
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    generalManager: "",
    regionalManager: "",
    designation: "",
    designation2: "",
  });

  const openPrintModal = (modalBatchNumber) => {
    setIsPrintModalOpen(true);
    setFormData({ ...formData, modalBatchNumber });

    // Call any other necessary functions or set any state related to opening the modal here
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to close the print modal
  const closePrintModal = () => {
    setIsPrintModalOpen(false);
    // Reset any state related to the modal here
  };

  const fetchBatchDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.nha.gov.ph/ep/api/batch/${batchNumber}`
      );
      setBatch(response.data);
    } catch (error) {
      console.error("Error fetching batch details:", error);
    }
  };

  const handleHeaderCheckboxClick = (isChecked) => {
    if (isChecked) {
      // If header checkbox is checked, select all enabled beneficiaries
      setSelectedIds(
        beneficiaries
          .filter((beneficiary) => beneficiary.step <= 2)
          .map((beneficiary) => beneficiary.id)
      );
    } else {
      // If header checkbox is unchecked, deselect all beneficiaries
      setSelectedIds([]);
    }
  };

  const handleRowCheckboxClick = (clickedItem) => {
    const id = clickedItem.id;

    // Check if the id is already in the selectedIds array
    const isSelected = selectedIds.includes(id);

    if (isSelected) {
      // If the checkbox is already selected, unselect it
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      // Otherwise, add the id to the selectedIds array
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    // Fetch batch details initially
    fetchBatchDetails();

    // Set up a timer to fetch batch details periodically (e.g., every 30 seconds)
    const intervalId = setInterval(fetchBatchDetails, 30000);

    // Clean up the timer on component unmount
    return () => clearInterval(intervalId);
  }, [batchNumber]);

  if (!batch) {
    return <p>Loading...</p>;
  }

  // Extracting all beneficiaries in the batch
  const beneficiaries = batch.Beneficiaries || [];
  const totalBeneficiaries = beneficiaries.length;
  const totalPages = Math.ceil(totalBeneficiaries / itemsPerPage);
  const firstBeneficiary = beneficiaries[0];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBeneficiaries = beneficiaries.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handleNextButtonClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevButtonClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const remainingRows = itemsPerPage - currentBeneficiaries.length;
  const placeholders = Array.from({ length: remainingRows }, (_, index) => ({
    id: `placeholder-${index}`,
    index: "",
    lastName: "",
    firstName: "",
    middleName: "",
    suffix: "",
  }));

  const handleRequest = () => {
    if (batchNumber && selectedIds.length > 0) {
      const state = {
        batchNumber: batchNumber,
        selectedIds: selectedIds,
      };
      navigate(`/request/${batchNumber}`, { state });
    } else {
      toast.error("Please select beneficiaries");
    }
  };

  const calculateSequenceNumber = (pageIndex, indexOnPage) => {
    return pageIndex * itemsPerPage + indexOnPage + 1;
  };

  // Check if all row checkboxes are disabled
  const allRowCheckboxesDisabled = currentBeneficiaries.every(
    (beneficiary) => beneficiary.step > 2
  );

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper pl-2 pr-2 pb-3">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Batch Details</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pl-2 pr-2">
          <div className="container-fluid">
            <div className="mt-4">
              <div
                className="card elevation-2"
                style={{
                  borderTop: "4px solid #292726",
                  borderRadius: "15px",
                }}
              >
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">List of Batch</h3>
                </div>

                <div
                  className="card-body table-responsive p-0"
                  style={{ height: 400 }}
                >
                  <div>
                    <table className="table table-head-fixed table-hover table-bordered text-wrap">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <input
                              type="checkbox"
                              style={{
                                width: "12px",
                              }}
                              checked={
                                selectedIds.length ===
                                beneficiaries.filter(
                                  (beneficiary) => beneficiary.step <= 2
                                ).length
                              }
                              onChange={(e) =>
                                handleHeaderCheckboxClick(e.target.checked)
                              }
                              disabled={allRowCheckboxesDisabled}
                            />
                          </th>
                          <th
                            style={{
                              width: "150px",
                            }}
                          >
                            Sequence No.
                          </th>
                          <th>Last Name</th>
                          <th>First Name</th>
                          <th>Middle Name</th>
                          <th>Suffix</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {[...currentBeneficiaries, ...placeholders].map(
                          (beneficiary, index) => (
                            <tr key={beneficiary.id} className="td-hover">
                              <td className="text-center">
                                {beneficiary.lastName ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      width: "12px",
                                    }}
                                    disabled={beneficiary.step > 2}
                                    checked={selectedIds.includes(
                                      beneficiary.id
                                    )}
                                    onChange={() =>
                                      handleRowCheckboxClick(beneficiary)
                                    }
                                  />
                                ) : (
                                  <div
                                    style={{
                                      height: "19px",
                                    }}
                                  ></div>
                                )}
                              </td>
                              {beneficiary.batchNumber ? (
                                <td className="text-center">
                                  {calculateSequenceNumber(
                                    currentPage - 1,
                                    index
                                  )}
                                </td>
                              ) : (
                                <td> </td>
                              )}

                              <td>{beneficiary.lastName}</td>
                              <td>{beneficiary.firstName}</td>
                              <td>{beneficiary.middleName}</td>
                              <td>{beneficiary.suffix}</td>
                              <td className="text-center">
                                {beneficiary.lastName ? (
                                  <>
                                    <Link
                                      to={`/beneficiary-info/${beneficiary.id}`}
                                    >
                                      <BsEye className="btn-icon" />
                                    </Link>
                                  </>
                                ) : (
                                  " "
                                )}
                              </td>
                              <td>{beneficiary.status}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="card-footer"
                  style={{
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <div className="row cardFooterCenter">
                    <div className="col-sm-6">
                      <Button
                        variant="contained"
                        className="w-25 float-right text-dark cardFooterItem"
                        style={{
                          backgroundColor: "#2be0a9",
                          borderRadius: "50px",
                        }}
                        onClick={handleRequest}
                      >
                        Request &nbsp; <BsIcons.BsArrowRight />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BatchDetails;
