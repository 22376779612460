import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import axios from "axios";

const QrDetails = () => {
  const { controlNumber } = useParams();
  const [verificationMessage, setVerificationMessage] = useState("");
  const [beneficiaryInfo, setBeneficiaryInfo] = useState(null); // Add state for beneficiary info
  useEffect(() => {
    const verifyBeneficiary = async () => {
      if (!controlNumber) return; // Early return if no control number

      try {
        const response = await axios.get(
          `https://api.nha.gov.ph/ep/verify-ep`,
          {
            params: {
              controlNumber: controlNumber,
            },
          }
        );
        setVerificationMessage(response.data.message || response.data.error);
        setBeneficiaryInfo(response.data.beneficiaryInfo || null); // Set beneficiary info in state
      } catch (error) {
        setVerificationMessage("Failed to verify control number.");
      }
    };

    verifyBeneficiary(); // Call the verify function

    // Optionally, you can return a cleanup function here if needed
  }, [controlNumber]);

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>QR Details</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="pl-2 pr-2">
        <div className="container-fluid d-flex justify-content-center"></div>

        <div
          className="container-fluid d-flex justify-content-center"
          style={{ marginTop: "10px" }}
        >
          <div
            className="card"
            style={{
              width: "800px",
              borderTop: "4px solid #292726",
              borderRadius: "15px",
            }}
          >
            <div className="card-header card-title font-weight-bold">
              Data Information
            </div>

            <div className="card-body">
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item border-top-0">
                  <span className="text-muted">EP Number</span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo?.controlNumber || "-"}
                  </span>
                </li>
                <li className="list-group-item border-top-0">
                  <span className="text-muted">
                    EP Bearer(s) <br />
                    <small className="text-xs mt-0">
                      <i>First, Middle, Last Name and Suffix</i>
                    </small>
                  </span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo ? (
                      <>
                        <div>
                          {beneficiaryInfo.firstName}{" "}
                          {beneficiaryInfo.middleName}{" "}
                          {beneficiaryInfo.lastName} {beneficiaryInfo.suffix}
                        </div>
                        {beneficiaryInfo.Spouse &&
                          beneficiaryInfo.Spouse.spouseLastName && (
                            <div>
                              {beneficiaryInfo.Spouse.spouseLastName}{" "}
                              {beneficiaryInfo.Spouse.spouseFirstName}{" "}
                              {beneficiaryInfo.Spouse.spouseMiddleName}
                            </div>
                          )}
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                </li>
                <li className="list-group-item border-top-0">
                  <span className="text-muted">Relocated From</span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo?.PointOfOrigin
                      ? `${beneficiaryInfo.PointOfOrigin.projectName}, ${
                          beneficiaryInfo.PointOfOrigin.barangayAddr || ""
                        }, ${beneficiaryInfo.PointOfOrigin.cityAddr || ""}, ${
                          beneficiaryInfo.PointOfOrigin.provinceAddr || ""
                        }`
                      : "-"}
                  </span>
                </li>
                <li className="list-group-item border-top-0">
                  <span className="text-muted">Resettled to</span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo?.Resettlement
                      ? `${
                          beneficiaryInfo.Resettlement.resettlementSiteName
                        }, ${beneficiaryInfo.Resettlement.barangayAddr}, ${
                          beneficiaryInfo.Resettlement.city || ""
                        }, ${beneficiaryInfo.Resettlement.province || ""}`
                      : "-"}
                  </span>
                </li>
                <li className="list-group-item border-top-0">
                  <span className="text-muted">
                    Resettlement Project Chargeability
                  </span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo?.Resettlement?.chargeability || "-"}
                  </span>
                </li>
                <li className="list-group-item border-top-0">
                  <span className="text-muted">Date Relocated</span>
                  <span className="float-right font-weight-bold">
                    {beneficiaryInfo?.relocationDate
                      ? formatDate(beneficiaryInfo.relocationDate)
                      : "-"}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QrDetails;
