import React, { useState } from "react";
import "./Login.css";
import * as BsIcons from "react-icons/bs";
import deptLogo from "../dist/img/deptLogo.png";
import nhaLogo from "../dist/img/nha-logo.png";
// mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  // mui
  const labelStyle = {
    fontWeight: "normal",
  };

  // stepper
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [employeeNo, setEmployeeNo] = useState(""); // Employee number for Forgot Password
  const navigate = useNavigate();
  const [logInStatus, setLogInStatus] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/login",
        { email, password },
        { withCredentials: true }
      );

      if (response.data.status === "success") {
        const { firstName, accessLevel, lastName } = response.data.user;

        // Set the cookies with secure options
        Cookies.set("firstName", firstName, { expires: 1 });
        Cookies.set("accessLevel", accessLevel, { expires: 1 });
        Cookies.set("lastName", lastName, { expires: 1 });

        navigate("/home");
      } else {
        setLogInStatus("Login failed. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized login, show an alert
        toast.error("Unauthorized login. Please check your credentials.");
        return;
      }
      console.error("Login error:", error); // Log the error
      toast.error("Login failed:", error);
      setLogInStatus("Login Failed. Please try again");

      return;
    }
  };

  const handleForgotPassword = async () => {
    if (!email || !employeeNo) {
      toast.error("Please provide both Employee Number and Email Address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.nha.gov.ph/ep/trx_forgot_password",
        { email, employeeNo }
      );

      if (response.data.message) {
        toast.success(response.data.message);
        setCurrentStep(1); // go back to login after sending email
      } else {
        toast.error("Failed to send new password. Please try again.");
      }
    } catch (error) {
      console.error("Error sending new password:", error);
      toast.error("Error sending new password. Please try again.");
    }
  };

  return (
    <div className="login-page" style={{ backgroundColor: "#f1f1f4" }}>
      <div className="login-box">
        <div
          className="card elevation-2"
          style={{
            height: "400px",
            borderTop: "solid 4px #2be0a9",
            backgroundColor: "#f5f6f8",
          }}
        >
          <div className="card-body mt-2">
            {currentStep === 1 && (
              <div className="text-center">
                <img
                  src={nhaLogo}
                  className="img-circle"
                  alt="nha Logo"
                  style={{ width: "50px" }}
                />{" "}
                &nbsp;
                <p className="login-title text-md font-weight-bold mb-0">
                  <b>NATIONAL HOUSING AUTHORITY</b>
                </p>
                <h5>Entry Pass</h5>
                <br />
                <TextField
                  className="mb-3"
                  id="outlined-basic"
                  value={email}
                  label="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="text"
                />
                <TextField
                  id="outlined-basic"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  type="password"
                />
                <small className="float-right text-muted mt-2">
                  Forgot password? Click{" "}
                  <a href="#" onClick={() => setCurrentStep(currentStep + 1)}>
                    {" "}
                    Here{" "}
                  </a>
                </small>
                <Button
                  type="submit"
                  variant="contained"
                  className="w-100 mt-4 text-dark"
                  onClick={handleLogin}
                  size="large"
                  style={{
                    backgroundColor: "#2be0a9",
                    borderRadius: "50px",
                  }}
                >
                  Sign In
                </Button>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <div className="text-left mb-4">
                  <small>
                    <a
                      href="#"
                      onClick={() => setCurrentStep(currentStep - 1)}
                      style={{ color: "#34a8ff" }}
                    >
                      <BsIcons.BsArrowLeft
                        className="mb-1"
                        style={{ color: "#34a8ff" }}
                      />{" "}
                      <b>Back to Login</b>
                    </a>
                  </small>
                </div>
                <div
                  className="text-left mb-5 mt-2"
                  style={{ lineHeight: "10px" }}
                >
                  <h4 className="font-weight-bold">Forgot Password?</h4>
                  <small>Enter your details to reset your password.</small>
                </div>

                <TextField
                  className="mb-3"
                  id="outlined-basic"
                  label="Employee No."
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  value={employeeNo}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers and limit the length to 7
                    if (/^\d{0,7}$/.test(value)) {
                      setEmployeeNo(value);
                    }
                  }}
                  type="text"
                />

                <TextField
                  className="mb-3"
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ style: labelStyle }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                />
                <Button
                  type="button"
                  variant="contained"
                  className="w-100 mt-4 text-dark"
                  onClick={handleForgotPassword}
                  size="large"
                  style={{
                    backgroundColor: "#2be0a9",
                    borderRadius: "50px",
                  }}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
